import axios from 'axios';
import api from './ApiMiddleware';
import monkeyApi from './MonkeyApiMiddleware';
import TokenService from './TokenService';

// Paginated
const getClients = (customerId) => {
  return api.get(`/api/v1/clients?customerId=${customerId}`);
};

const getUsers = (customerId) => {
  return api.get(`/api/v1/user/users?customerId=${customerId}`);
};

const getCustomer = (customerId) => {
  return api.get(`/api/v1/customer/${customerId}`);
};

const getMainCustomers = () => {
  return api.get(`/api/v1/systemadmin/customers`);
};

const getSubCustomers = (customerId) => {
  return api.get(`/api/v1/systemadmin/subcustomers/${customerId}`);
};

const getBillingActions = (customerId) => {
  return api.get(`/api/v1/systemadmin/customer/billingrecords/${customerId}`);
};

const getCustomerClients = (customerId) => {
  return api.get(`/api/v1/customer/${customerId}/clients`);
};

const getCustomerStatistics = (customerId) => {
  return api.get(`/api/v1/customer/${customerId}/statistics`);
};

const updateLogo = (customerId, logoFile) => {
  const formData = new FormData();
  formData.append('logoFile', logoFile);

  return axios.post(`/api/v1/customer/${customerId}/logo`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
    },
  });
};

const createCustomer = (
  name,
  organizationNumber,
  phoneNumber,
  contactPerson,
  billingEmail,
  customerType,
  mainCustomerId
) => {
  return api.post(`/api/v1/customer`, {
    name,
    organizationNumber,
    phoneNumber,
    contactPerson,
    billingEmail,
    customerType,
    mainCustomerId,
  });
};

const updateCustomer = (customer) => {
  return api.put(`/api/v1/customer/${customer.id}`, {
    name: customer.name,
    mainCustomerId: customer.mainCustomerId,
    organizationNumber: customer.organizationNumber,
    contactPerson: customer.contactPerson,
    phoneNumber: customer.phoneNumber,
    billingEmail: customer.billingEmail,
    supportEmail: customer.supportEmail,
    supportPhoneNumber: customer.supportPhoneNumber,
  });
};

const getOnboardingInfo = () => {
  return monkeyApi.get(`/api/v1/customer/onboarding`);
};

const CustomerService = {
  getClients,
  getUsers,
  getCustomer,
  getMainCustomers,
  getSubCustomers,
  getBillingActions,
  getCustomerClients,
  getCustomerStatistics,
  updateLogo,
  createCustomer,
  updateCustomer,
  getOnboardingInfo,
};

export default CustomerService;
