/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-alert */
import { useHistory } from 'react-router-dom';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import TokenService from '../../../../../Services/TokenService';
import UserService from '../../../../../Services/UserService';

function DeleteUserModal({
  show,
  handleClose,
  userToDelete,
  deletionText,
  deleteOwnAccount,
}) {
  const history = useHistory();
  const clickDelete = async () => {
    await UserService.DeleteUser(userToDelete.id)
      .then((response) => {
        if (deleteOwnAccount) {
          TokenService.removeToken();
          localStorage.clear();
          sessionStorage.clear();
          history.push('/');
        } else if (!deleteOwnAccount) {
          if (response) {
            window.location = '/app/settings/users';
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          alert('Kunde inte ta bort användare');
        }
        if (error.response.status === 403) {
          alert('Du har ej behörighet att ta bort användare');
        }
        if (error.response.status === 500) {
          alert('Något gick fel, försök igen senare');
        }
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="border-0"
    >
      <Modal.Header className="border-0">
        <Modal.Title>Ta bort användare</Modal.Title>
        <Button className="btn-close" bsPrefix="button" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body className="border-0">
        <p>{deletionText}</p>
        <br />
        <p>Vill du fortsätta?</p>
        <br />
        <Row>
          <Col md={{ span: 3, offset: 6 }}>
            <Button variant="outline-primary" onClick={handleClose}>
              Avbryt
            </Button>
          </Col>
          <Col md={{ span: 3, offset: 0 }}>
            {' '}
            <Button
              variant="danger"
              onClick={() => {
                clickDelete();
              }}
            >
              Ta bort
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default DeleteUserModal;
