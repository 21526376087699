import './SystemAdmin.scss';
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import UserService from '../../../Services/UserService';
import TabNav from '../../../Components/TabNav/TabNav';
import XpektorSlider from '../../../Components/XpektorSlider/XpektorSlider';
import ServiceStatusSettings from './ServiceStatusSettings/ServiceStatusSettings';
import Customers from './Customers/Customers';

export default function SystemAdmin() {
  const [activeTab, setActiveTab] = useState('');
  const history = useHistory();
  const location = useLocation();

  const isLocation = (path) => {
    return window.location.href.indexOf(path) !== -1;
  };

  useEffect(() => {
    if (isLocation('systemadmin/drift')) {
      setActiveTab('drift');
    } else {
      setActiveTab('customers');
    }
  }, [location]);

  useEffect(() => {
    const getData = async () => {
      const user = await UserService.getCurrentUser();
      if (user.role !== 10) {
        history.push('/app/dashboard');
      }

      if (isLocation('systemadmin/customers')) {
        setActiveTab('customers');
      } else if (isLocation('systemadmin/drift')) {
        setActiveTab('drift');
      }
    };
    document.title = 'System Admin';
    getData();
  }, []);

  return (
    <div className="system-admin-wrapper">
      <div className="system-admin-head">
        <p className="system-admin-title">System Admin</p>
      </div>
      <div className="system-admin-nav-container">
        <TabNav>
          <TabNav.Tab
            title="Kunder"
            active={activeTab === 'customers'}
            onClick={() =>
              history.replace({ pathname: '/app/systemadmin/customers' })
            }
          />
          <TabNav.Tab
            title="Drift"
            active={activeTab === 'drift'}
            onClick={() =>
              history.replace({ pathname: '/app/systemadmin/drift' })
            }
          />
        </TabNav>
      </div>
      <XpektorSlider
        activeSlideIndex={
          activeTab === 'customers' ? 0 : activeTab === 'drift' ? 1 : ''
        }
      >
        <XpektorSlider.Slide hidden={activeTab !== 'customers'}>
          <Customers />
        </XpektorSlider.Slide>
        <XpektorSlider.Slide hidden={activeTab !== 'drift'}>
          <ServiceStatusSettings />
        </XpektorSlider.Slide>
      </XpektorSlider>
    </div>
  );
}
