/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faArrowRight,
  faArrowUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';
import CardButton from '../../../Components/CardButton/CardButton';
import SKVLogo from '../../../Images/Logos/SKV_RGB_li.png';

export default function SkvAuthStep({
  allowed,
  completed,
  openSkvTab,
  error,
  errorText,
  loading,
}) {
  return (
    <div className="auth-step">
      {completed ? (
        <div className="auth-step-completed-content">
          <FontAwesomeIcon
            className="auth-complete-icon"
            icon={faCircleCheck}
          />
          <p className="auth-complete-header">Skatteverket är kopplat!</p>
        </div>
      ) : (
        <>
          <p className="auth-step-header">Koppla på skattekonto</p>
          <p className="auth-step-description">
            Genom att ge tillgång till skattekontot får handläggaren förståelse
            för företagets skatter och regelefterlevnad. Detta är något som
            underlättar handläggningen.
          </p>
          <button
            type="button"
            className="auth-step-help-link"
            onClick={() =>
              window.open('/tutorials?as=skv', '_blank', 'noopener,noreferrer')
            }
          >
            <p>Instruktioner för att koppla Skatteverket.</p>
            <FontAwesomeIcon
              className="auth-step-help-link-icon"
              icon={faArrowUpRightFromSquare}
            />
          </button>
          {error ? (
            <div className="auth-step-error">
              <p>{errorText ?? 'Oops, något gick fel.'}</p>
            </div>
          ) : (
            ''
          )}
          <div className="integration-options-column">
            <CardButton
              textHeader="Skatteverket"
              textContent={
                !allowed
                  ? 'Kräver en koppling till ett bokföringssystem och/eller bank.'
                  : 'OBS! Endast behörig firmatecknare kan koppla Skatteverket.'
              }
              icon={faArrowRight}
              image={SKVLogo}
              onClick={openSkvTab}
              disabled={!allowed || loading}
              styleClass={!allowed ? 'integration-partner-button-disabled' : ''}
            />
          </div>
          {allowed ? (
            <p className="auth-step-description">
              <i>
                Det kan ta några minuter innan vi ser att du har kopplat
                Skatteverket. Du kan trycka på slutför eller stänga den här
                sidan när du är klar.
              </i>
            </p>
          ) : (
            ''
          )}
        </>
      )}
    </div>
  );
}
