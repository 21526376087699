import { useEffect } from 'react';

export function useClickOutside(ref, onClickOutside) {
  useEffect(() => {
    /**
     * Invoke Function onClick outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    }
    // Bind
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // dispose
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClickOutside]);
}

export function OpenDemoLink() {
  window.open(
    'https://meetings-eu1.hubspot.com/jacob-liebermann?embed=true',
    '_blank',
    'noopener,noreferrer'
  );
}

export function LuhnCheckSSN(value) {
  let newValue = value.replaceAll('-', '');

  if (newValue.length === 12) {
    newValue = newValue.slice(2, 12);
  }

  if (newValue.length === 10) {
    let sum = 0;
    let shouldDouble = false;
    for (let i = newValue.length - 1; i >= 0; i -= 1) {
      let current = Number(newValue[i]);
      if (shouldDouble) current *= 2;
      current = Math.trunc(current / 10) + (current % 10);
      sum += current;
      shouldDouble = !shouldDouble;
    }
    return sum % 10 === 0;
  }

  return false;
}

export const monthTranslations = [
  'Januari',
  'Februari',
  'Mars',
  'April',
  'Maj',
  'Juni',
  'Juli',
  'Augusti',
  'September',
  'Oktober',
  'November',
  'December',
];
