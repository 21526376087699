import '../../Modals/XpektorBootstrapModal.scss';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  ClassicForm,
  FormFileUpload,
  FormButton,
} from '../../../../Components/XpektorForms/XpektorForms';
import CustomerService from '../../../../Services/CustomerService';
/* eslint-disable react/prop-types */

export default function ChangeLogoModal({
  customer,
  handleClose,
  onLogoUpdate,
}) {
  const [submitting, setSubmitting] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [previewFile, setPreviewFile] = useState(null);
  const [imageError, setImageError] = useState('');

  const validateAndSubmit = async (e) => {
    e.preventDefault();

    setSubmitting(true);
    await CustomerService.updateLogo(customer.id, imageFile)
      .then(() => {
        onLogoUpdate();
      })
      .catch(() => {
        setImageError('Gick inte att ladda upp');
      });
  };

  return (
    <Modal
      show
      onHide={handleClose}
      backdrop="static"
      centered
      keyboard={false}
      className="border-0 xpektor-bootstrap-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Ändra logga</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="image-window">
          {previewFile || customer.logo ? (
            <img src={previewFile ?? customer.logo} alt="logo" />
          ) : (
            <p className="missing-text">Logga saknas</p>
          )}
        </div>
        <ClassicForm onSubmit={validateAndSubmit}>
          <FormFileUpload
            label="Ladda upp fil"
            formats=".png"
            onChange={(e) => {
              if (e.target.files.length > 0) {
                if (e.target.files[0].size > 10000000) {
                  setImageError('Filen kan inte vara större än 10 MB');
                } else {
                  setImageError('');
                  setPreviewFile(URL.createObjectURL(e.target.files[0]));
                  setImageFile(e.target.files[0]);
                }
              }
            }}
            infoText={imageError}
          />
          <div className="spaced-row">
            <FormButton
              text="Spara"
              disabled={
                submitting || imageFile === null || imageError.length > 0
              }
            />
          </div>
        </ClassicForm>
      </Modal.Body>
    </Modal>
  );
}
