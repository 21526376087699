/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faChevronRight,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import XpektorButton from '../../../../Components/XpektorButton/XpektorButton';
import ArrowTab from '../../../../Components/ArrowTab/ArrowTab';
import CustomerService from '../../../../Services/CustomerService';
import UserService from '../../../../Services/UserService';
import AddSupportInfoModal from '../../Modals/AddSupportInfoModal';
import ScreeningResultModal from '../../Modals/ScreeningResultModal';
import { TranslateKYCTriggers } from '../../../../Components/Utilities/KYCTriggerTexts';

const ignoredTriggerIds = [3, 4, 5, 6, 31, 32];
const warningTriggerIds = [1, 2];

function BoardMemberDeviationTrigger({ deviation }) {
  return (
    <div className="trigger-container">
      <div className="trigger-content">
        <p className="trigger-title error">Styrelse</p>
        <p className="trigger-text">
          <b>{deviation.name}</b> finns {deviation.type === 1 ? 'inte' : ''} hos
          Bolagsverket men har {deviation.type === 1 ? '' : 'inte'} fyllts i.
        </p>
      </div>
    </div>
  );
}

function WebsiteTrigger({ websiteHit }) {
  let hitCategories = '';
  if (websiteHit.categories !== null && websiteHit.categories.length > 0) {
    for (let i = 0; i < websiteHit.categories.length; i += 1) {
      hitCategories = `${hitCategories} ${websiteHit.categories[i]}`;
      if (i < websiteHit.categories.length - 1) {
        hitCategories = `${hitCategories},`;
      }
    }
  }

  return (
    <div className="trigger-container">
      <div className="trigger-content">
        <p className="trigger-title error">Webbsida</p>
        <p className="trigger-text">
          Skanning av webbadressen för{' '}
          <b>
            {websiteHit.websiteType === 1
              ? 'bolagets hemsida'
              : 'bolagets e-handelsplattform'}
          </b>{' '}
          har kommit tillbaka som potentiellt skadlig.
          {hitCategories.length > 0 ? (
            <p className="trigger-text">Kategorier: {hitCategories}</p>
          ) : (
            ''
          )}
        </p>
      </div>
    </div>
  );
}

function ScreeningResultTrigger({ result, onClick }) {
  return (
    <button
      type="button"
      className="trigger-container trigger-button"
      onClick={onClick}
    >
      <div className="trigger-content">
        <p className="trigger-title warning">Screening</p>
        <p className="trigger-text">
          Finns resultat på <b>{result.name}</b>.
        </p>
      </div>
      <FontAwesomeIcon className="trigger-button-icon" icon={faChevronRight} />
    </button>
  );
}

function StandaloneTrigger({ trigger }) {
  const triggerType = warningTriggerIds.includes(trigger.id)
    ? 'warning'
    : 'error';
  return (
    <div className="trigger-container">
      <div className="trigger-content">
        <p className={`trigger-title ${triggerType}`}>
          {triggerType === 'warning' ? 'Varning' : 'Avvikelse'}
        </p>
        <p className="trigger-text">{trigger.texts.name}</p>
      </div>
    </div>
  );
}

export default function KYCView({ kycStatus, onCreateClick, onCancelClick }) {
  const [kycFormUrl, setKYCFormUrl] = useState('');
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [showAddSupportInfoModal, setShowAddSupportInfoModal] = useState(false);
  const [customerData, setCustomerData] = useState({});
  const [translatedTriggers, setTranslatedTriggers] = useState([]);

  const [selectedResult, setSelectedResult] = useState({});
  const [showResultModal, setShowResultModal] = useState(false);

  /* const getKYCDocumentLink = async () => {
    await KYCService.getDocumentLink()
      .then((response) => {
        window.open(response.data, '_blank', 'noopener,noreferrer');
      })
      .catch(() => {});
  }; */

  useEffect(() => {
    if (kycStatus !== null && Object.keys(kycStatus).length > 0) {
      setKYCFormUrl(
        `${window.location.protocol}//${window.location.host}/kyc-form?state=${kycStatus.stateId}`
      );
      const triggersToTranslate = kycStatus.triggers.filter(
        (trigger) => !ignoredTriggerIds.includes(Number(trigger))
      );
      setTranslatedTriggers(TranslateKYCTriggers(triggersToTranslate));
      setIsCreatingNew(false);
    }
  }, [kycStatus]);

  const [animatingCopied, setAnimatingCopied] = useState(false);
  const [animatingCopiedCount, setAnimatingCopiedCount] = useState(0);

  useEffect(() => {
    const animateCopied = () => {
      const newAnimatingCopiedCount = animatingCopiedCount + 1;
      if (newAnimatingCopiedCount === 2) {
        setAnimatingCopiedCount(0);
        setAnimatingCopied(false);
      } else {
        setAnimatingCopiedCount(newAnimatingCopiedCount);
      }
    };
    const timer = setTimeout(() => animatingCopied && animateCopied(), 1000);
    return () => clearTimeout(timer);
  }, [animatingCopiedCount, animatingCopied]);

  async function ValidateCustomerAndCreateNewProcess() {
    setIsCreatingNew(true);

    const user = await UserService.getCurrentUser();
    await CustomerService.getCustomer(user.customerId)
      .then((response) => {
        if (!response.data.supportPhoneNumber || !response.data.supportEmail) {
          setCustomerData(response.data);
          setShowAddSupportInfoModal(true);
          return;
        }
        onCreateClick();
      })
      .catch(() => {});
  }

  const updateCustomerAndCreateNewProcess = async (supportInfo) => {
    const newCustomer = { ...customerData };
    newCustomer.supportEmail = supportInfo.email;
    newCustomer.supportPhoneNumber = supportInfo.phone;

    await CustomerService.updateCustomer(newCustomer)
      .then(() => {
        onCreateClick();
        setShowAddSupportInfoModal(false);
      })
      .catch(() => {});
  };

  function TranslateFormType(type) {
    return type === 0
      ? 'Lån'
      : type === 1
      ? 'Företagskredit'
      : type === 2
      ? 'Factoring'
      : type === 3
      ? 'Orderfinansiering'
      : type === 4
      ? 'Leasing'
      : 'N/A';
  }

  const [cancelling, setCancelling] = useState(false);
  const [confirmCreateNew, setConfirmCreateNew] = useState(false);

  return (
    <div className="kyc-overview-wrapper">
      <div className="main-column">
        <div className="title-column">
          <div className="title-row">
            <h2>KYC</h2>
            <p className="info-text-panel">
              <FontAwesomeIcon icon={faInfoCircle} className="info-text-icon" />
              Denna KYC är anpassad för kreditmarknadsbranschen
            </p>
          </div>
          <div className="status-steps">
            <ArrowTab
              text="1. Öppen"
              inactive={kycStatus === null || kycStatus.state !== 1}
              color="#3e66fb"
            />
            <ArrowTab
              text="2. Inskickad"
              inactive={kycStatus === null || kycStatus.state !== 2}
              color="#ECA60F"
            />
            <ArrowTab
              text="3. Signerad"
              inactive={kycStatus === null || kycStatus.state < 3}
              color="#006400"
            />
          </div>
        </div>
        {kycStatus === null ? (
          <p className="empty-text">Finns ingen aktiv KYC process.</p>
        ) : Object.keys(kycStatus).length === 0 ? (
          <p className="empty-text">Hämtar...</p>
        ) : (
          <div className="data-column">
            <div className="entry-column">
              <p className="data-title">Formulärtyp:</p>
              <p>
                {kycStatus.formType !== null
                  ? TranslateFormType(kycStatus.formType)
                  : 'N/A'}
              </p>
            </div>
            <div className="entry-column">
              <p className="data-title">Datum skapat:</p>
              <p>
                {Moment.utc(kycStatus.dateCreated).local().format('YYYY-MM-DD')}
              </p>
            </div>
            <div className="entry-column">
              <p className="data-title">Senast besökt:</p>
              <p>
                {kycStatus.lastAccessed
                  ? Moment.utc(kycStatus.lastAccessed)
                      .local()
                      .format('YYYY-MM-DD')
                  : 'N/A'}
              </p>
            </div>
            <div className="entry-column">
              <p className="data-title">Datum signerat:</p>
              <p>
                {kycStatus.dateSealed
                  ? Moment.utc(kycStatus.dateSealed)
                      .local()
                      .format('YYYY-MM-DD')
                  : 'N/A'}
              </p>
            </div>
          </div>
        )}
        <div className="action-row">
          {kycStatus === null ? (
            <>
              <XpektorButton
                content="Starta ny process"
                onClick={() => {
                  ValidateCustomerAndCreateNewProcess();
                }}
                loading={isCreatingNew}
              />
              <p className="info-text">
                OBS! För varje startad KYC process tillkommer en kostnad på
                129kr på nästa faktura.
              </p>
            </>
          ) : kycStatus.state === 1 ? (
            <>
              <XpektorButton
                icon={!animatingCopied ? faCopy : ''}
                content={
                  animatingCopied ? 'Kopierad!' : 'Kopiera länk till formuläret'
                }
                onClick={() => {
                  navigator.clipboard.writeText(kycFormUrl);
                  setAnimatingCopied(true);
                }}
              />
              <XpektorButton
                content="Öppna formuläret"
                onClick={() => {
                  window.open(kycFormUrl, '_blank', 'noopener,noreferrer');
                }}
              />
            </>
          ) : kycStatus.state === 2 ? (
            <XpektorButton
              content="Avbryt"
              loading={cancelling}
              tertiary
              onClick={() => {
                setCancelling(true);
                onCancelClick()
                  .then(() => {})
                  .catch(() => {})
                  .finally(() => {
                    setCancelling(false);
                  });
              }}
            />
          ) : kycStatus.state >= 3 ? (
            <>
              <XpektorButton
                content="Granska dokument"
                secondary
                onClick={() => {
                  if (kycStatus.downloadLink) {
                    window.open(
                      kycStatus.downloadLink,
                      '_blank',
                      'noopener,noreferrer'
                    );
                  }
                }}
              />
              <div className="new-process-content">
                {confirmCreateNew ? (
                  <div className="choice-row">
                    <p className="confirm-text">Är du säker?</p>
                    <XpektorButton
                      content="Ja"
                      onClick={() => {
                        setConfirmCreateNew(false);
                        setIsCreatingNew(true);
                        onCreateClick();
                      }}
                    />
                    <XpektorButton
                      content="Nej"
                      tertiary
                      onClick={() => setConfirmCreateNew(false)}
                    />
                  </div>
                ) : (
                  <XpektorButton
                    content="Starta ny process"
                    onClick={() => {
                      setConfirmCreateNew(true);
                    }}
                    loading={isCreatingNew}
                  />
                )}
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="trigger-column">
        <p className="trigger-column-title">Varningar och avvikelser</p>
        {kycStatus !== null &&
        Object.keys(kycStatus).length !== 0 &&
        kycStatus.state >= 3 ? (
          <div className="trigger-list">
            {kycStatus.state === 3 ? (
              <div className="empty-triggers-sign in-progress">
                <p>Formuläret bearbetas, kom tillbaka om en stund.</p>
              </div>
            ) : kycStatus.triggers.length === 0 ? (
              <div className="empty-triggers-sign no-triggers">
                <FontAwesomeIcon className="icon" icon={faCircleCheck} />
                <p>Finns inga</p>
              </div>
            ) : (
              <>
                {translatedTriggers.map((trigger) => {
                  return (
                    <StandaloneTrigger
                      trigger={trigger}
                      key={`kyc-standalone-trigger-${trigger.id}`}
                    />
                  );
                })}
                {kycStatus.websiteScanHits.map((websiteHit) => {
                  return (
                    <WebsiteTrigger
                      websiteHit={websiteHit}
                      key={`kyc-website-trigger-${websiteHit.id}`}
                    />
                  );
                })}
                {kycStatus.boardMemberDeviations.map((deviation) => {
                  return (
                    <BoardMemberDeviationTrigger
                      deviation={deviation}
                      key={`kyc-deviation-trigger-${deviation.id}`}
                    />
                  );
                })}
                {kycStatus.screeningResults.map((result) => {
                  return (
                    <ScreeningResultTrigger
                      result={result}
                      key={`kyc-screening-trigger-${result.id}`}
                      onClick={() => {
                        setSelectedResult(result);
                        setShowResultModal(true);
                      }}
                    />
                  );
                })}
              </>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
      {showAddSupportInfoModal ? (
        <AddSupportInfoModal
          handleClose={() => {
            setShowAddSupportInfoModal(false);
            setIsCreatingNew(false);
          }}
          onSubmit={updateCustomerAndCreateNewProcess}
          customer={customerData}
        />
      ) : (
        ''
      )}
      {showResultModal ? (
        <ScreeningResultModal
          handleClose={() => {
            setShowResultModal(false);
            setSelectedResult({});
          }}
          result={selectedResult}
        />
      ) : (
        ''
      )}
    </div>
  );
}
