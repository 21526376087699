import MailIcon from '../../../Images/Icons/mail_icon.png';
import DocumentIcon from '../../../Images/Icons/document_icon.png';
import SparkleIcon from '../../../Images/Icons/sparkle_icon.png';

/* eslint-disable react/prop-types */
function EmailBox({ title, icon, email }) {
  return (
    <div className="email-box">
      <div className="title-row">
        <img src={icon} alt="email_icon" />
        <p className="email-title">{title}</p>
      </div>
      <a href={`mailto:${email}`} className="email-address">
        {email}
      </a>
    </div>
  );
}
export default function EmailSection() {
  return (
    <div className="email-section">
      <EmailBox title="Försäljning" email="sales@xpektor.com" icon={MailIcon} />
      <EmailBox title="Jobb" email="info@xpektor.com" icon={DocumentIcon} />
      <EmailBox title="Ekonomi" email="info@xpektor.com" icon={DocumentIcon} />
      <EmailBox title="Övrigt" email="info@xpektor.com" icon={SparkleIcon} />
    </div>
  );
}
