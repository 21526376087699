/* eslint-disable react/prop-types */
import './LabeledInfo.scss';

export default function LabeledInfo({
  label,
  info,
  animatedX,
  animatedY,
  animDelay = 0,
  divider,
}) {
  const animationStyle = {
    animationDelay: `${animDelay}s`,
  };

  return (
    <div className="labeled-time-mask">
      <div
        style={animationStyle}
        className={`labeled-time-content ${
          animatedY ? 'anim-y' : animatedX ? 'anim-x' : ''
        }`}
      >
        <p className="content">
          {label} <b>{info}</b>
        </p>
        {divider ? <p className="divider">|</p> : ''}
      </div>
    </div>
  );
}
