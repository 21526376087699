import './CreateAuthLinkModal.scss';
import { useHistory } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import AuthService from '../../../Services/AuthService';
import ClientService from '../../../Services/ClientService';
import XpektorButton from '../../../Components/XpektorButton/XpektorButton';
import {
  XpektorForm,
  FormInput,
  FormButton,
} from '../../../Components/XpektorForms/XpektorForms';
import RecipientService from '../../../Services/RecipientService';
import UserService from '../../../Services/UserService';

/* eslint-disable react/prop-types */
export default function CreateAuthLinkModal({
  handleClose,
  handleNewLink,
  onClientCreated,
}) {
  const history = useHistory();
  const [authUrl, setAuthUrl] = useState(null);

  const [orgNumberErrorText, setOrgNumberErrorText] = useState(null);
  const [orgNumber, setOrgNumber] = useState('');

  const [enableDataFetch, setEnableDataFetch] = useState(false);
  const [dataFetchErrorText, setDataFetchErrorText] = useState(null);
  const [fetchingData, setFetchingData] = useState(false);
  const [createdClient, setCreatedClient] = useState({});

  const [animatingCopied, setAnimatingCopied] = useState(false);
  const [animatingCopiedCount, setAnimatingCopiedCount] = useState(0);

  function ValidateOrgNumber(org) {
    const validLength = org.length === 10;

    if (!validLength) {
      setOrgNumberErrorText('Organisationsnummret måste bestå av 10 siffror');
    } else {
      setOrgNumberErrorText('');
    }

    setEnableDataFetch(validLength === true);
    return validLength;
  }

  function SetOrgNumberInput(newValue) {
    if (newValue.length <= 10) {
      setOrgNumber(newValue);
      ValidateOrgNumber(newValue);
    }
  }

  useEffect(() => {
    const animateCopied = () => {
      const newAnimatingCopiedCount = animatingCopiedCount + 1;
      if (newAnimatingCopiedCount === 2) {
        setAnimatingCopiedCount(0);
        setAnimatingCopied(false);
      } else {
        setAnimatingCopiedCount(newAnimatingCopiedCount);
      }
    };
    const timer = setTimeout(() => animatingCopied && animateCopied(), 1000);
    return () => clearTimeout(timer);
  }, [animatingCopiedCount, animatingCopied]);

  const getCompanyAndCreateClient = async (event) => {
    event.preventDefault();

    setFetchingData(true);
    const companyInfo = await ClientService.getBasicCompanyInfo(orgNumber)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setDataFetchErrorText(
            'Hittade inget företag med det organisationsnummret'
          );
        } else {
          setDataFetchErrorText('Kunde inte hämta företaget');
        }
        setFetchingData(false);
        return null;
      });

    if (companyInfo === null) return;

    if (companyInfo.errorCode !== null) {
      setDataFetchErrorText(`${companyInfo.errorCode}`);
      setFetchingData(false);
      return;
    }

    setDataFetchErrorText(null);
    await ClientService.createClient(
      companyInfo.name,
      companyInfo.organizationNumber
    )
      .then((clientResponse) => {
        setCreatedClient(clientResponse.data);
        RecipientService.PostRecipient(
          UserService.getCurrentUser().id,
          clientResponse.data.id
        );

        return AuthService.generateExternalAuthUrlForClient(
          clientResponse.data.id,
          null
        );
      })
      .then((linkResponse) => {
        setAuthUrl(linkResponse.data);
        if (handleNewLink) {
          handleNewLink();
        }
      })
      .catch(() => {
        setAuthUrl(null);
        setCreatedClient({});
        setDataFetchErrorText('Något gick fel');
      })
      .finally(() => {
        if (onClientCreated) {
          onClientCreated();
        }
        setFetchingData(false);
      });
  };

  const copyLink = () => {
    navigator.clipboard.writeText(authUrl);
  };

  return (
    <Modal
      show
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="border-0 create-auth-link-modal"
    >
      <Modal.Header>
        <Modal.Title>Lägg till klient</Modal.Title>
        <Button className="btn-close" bsPrefix="button" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body className="border-0">
        <div className="auth-link-modal-content">
          <p className="auth-link-description">
            Lägg till en organisation och få en aktiveringslänk som kan skickas
            till en klient där de kan ge Xpektor behörighet att hämta data om
            deras företag. Har du behörighet kan du använda länken själv.
            <br />
            <br />
            Du kommer att få ett mail varje gång klienten kopplar på något.
          </p>

          <XpektorForm onSubmit={getCompanyAndCreateClient}>
            <FormInput
              label="Org. nummer (10 siffror)"
              type="number"
              value={orgNumber}
              onChange={(e) => {
                SetOrgNumberInput(e.target.value);
              }}
              infoText={orgNumberErrorText}
            />
            <FormButton
              text={fetchingData ? 'Hämtar...' : 'Lägg till klient'}
              disabled={!enableDataFetch || fetchingData}
            />
          </XpektorForm>
          {(Object.keys(createdClient).length > 0 ||
            dataFetchErrorText !== null) &&
            fetchingData === false && (
              <div
                className={`result-display ${
                  dataFetchErrorText != null ? 'error-result' : 'good-result'
                }`}
              >
                <p className="result-title">
                  {dataFetchErrorText
                    ? 'Kunde inte lägga till klient'
                    : createdClient.alreadyExists
                    ? 'Klienten finns redan'
                    : 'Klient tillagd'}
                </p>
                {dataFetchErrorText ? (
                  <p>{dataFetchErrorText}</p>
                ) : (
                  <p>
                    {createdClient.name}
                    <br />
                    {createdClient.organizationNumber}
                  </p>
                )}
              </div>
            )}
          {Object.keys(createdClient).length > 0 &&
            dataFetchErrorText === null &&
            fetchingData === false && (
              <div className="action-row">
                {authUrl !== null ? (
                  <>
                    <XpektorButton
                      icon={animatingCopied ? '' : faCopy}
                      content={animatingCopied ? 'Kopierad!' : 'Kopiera länk'}
                      secondary
                      onClick={() => {
                        copyLink();
                        setAnimatingCopied(true);
                      }}
                    />
                    <XpektorButton
                      content="Öppna länk i ny flik"
                      onClick={() =>
                        window.open(authUrl, '_blank', 'noopener,noreferrer')
                      }
                    />
                  </>
                ) : (
                  ''
                )}
                <XpektorButton
                  content="Till klient"
                  onClick={() =>
                    history.push(
                      `/app/companyinfo/${createdClient.id}/regulatory`
                    )
                  }
                />
              </div>
            )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
