import './Sidebar.scss';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  faPowerOff,
  faLock,
  faUser,
  faChartPie,
  faScrewdriverWrench,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import TokenService from '../../../Services/TokenService';
import UserService from '../../../Services/UserService';
import XpektorLogo from '../../../Components/XpektorLogo/XpektorLogo';
import SidebarButton from './SidebarButton/SidebarButton';

export default function Sidebar() {
  const history = useHistory();
  const [authState, setAuthState] = useState({});

  useEffect(() => {
    const user = UserService.getCurrentUser();
    setAuthState((state) => ({ ...state, user }));
  }, []);

  const handleLogout = () => {
    TokenService.removeToken();
    localStorage.clear();
    sessionStorage.clear();
    setAuthState((state) => ({ ...state, user: null }));
    history.push('/');
  };

  const redirectTo = (path) => {
    history.push(path);
  };

  const isLocation = (path) => {
    return history.location.pathname.includes(path);
  };

  return (
    <div className="sidebar-wrapper">
      <XpektorLogo target="/app/dashboard" darkMode />
      <div className="main-button-container">
        <SidebarButton
          title="Alla klienter"
          click={() => redirectTo('/app/dashboard')}
          icon={faChartPie}
          active={
            isLocation('/app/dashboard') || isLocation('/app/companyinfo')
          }
        />
        {authState.user?.role > 0 && (
          <SidebarButton
            title="Admin"
            click={() => redirectTo('/app/settings/general')}
            icon={faUser}
            active={isLocation('/app/settings')}
          />
        )}
        {authState.user?.role === 10 && (
          <SidebarButton
            title="System Admin"
            click={() => redirectTo('/app/systemadmin/customers')}
            icon={faScrewdriverWrench}
            active={isLocation('/app/systemadmin')}
          />
        )}
        <SidebarButton
          title="FAQ"
          click={() => redirectTo('/app/FAQ')}
          icon={faCircleInfo}
          active={isLocation('/app/FAQ')}
        />
      </div>
      <div className="footer-button-container">
        <SidebarButton
          title="Byt lösenord"
          click={() => redirectTo('/app/changePassword')}
          icon={faLock}
          active={isLocation('/app/changePassword')}
        />
        <SidebarButton
          title="Logga ut"
          click={handleLogout}
          icon={faPowerOff}
        />
      </div>
    </div>
  );
}
