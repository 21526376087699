const kycTriggerTexts = {};

/* triggerTexts[0] = {
  SE: {
    name: 'Sviktande eller otillräckligt resultat',
  },
}; */

kycTriggerTexts[1] = {
  SE: {
    name: 'Ägnar sig åt valutaväxling (högriskbransch)',
  },
};

kycTriggerTexts[2] = {
  SE: {
    name: 'Ägnar sig åt Kryptovalutaväxling (högriskbransch)',
  },
};

kycTriggerTexts[3] = {
  SE: {
    name: 'Avvikelser i styrelsen',
  },
};

kycTriggerTexts[4] = {
  SE: {
    name: 'Matchning finns på bolags-screening',
  },
};

kycTriggerTexts[5] = {
  SE: {
    name: 'Matchning vid screening på individnivå',
  },
};

kycTriggerTexts[6] = {
  SE: {
    name: 'Matchning vid screening på relaterade bolag',
  },
};

kycTriggerTexts[7] = {
  SE: {
    name: 'Har utländsk valuta men bolaget saknar aktivitet med utlandet enligt formuläret',
  },
};

kycTriggerTexts[8] = {
  SE: {
    name: 'Omsättning de senaste tre månaderna är lägre än den uppskattade',
  },
};

kycTriggerTexts[9] = {
  SE: {
    name: 'Omsättning de senaste sex månaderna är lägre än den uppskattade',
  },
};

kycTriggerTexts[10] = {
  SE: {
    name: 'Omsättning de senaste tolv månaderna är lägre än den uppskattade',
  },
};

kycTriggerTexts[11] = {
  SE: {
    name: 'Omsättning de senaste tre månaderna är högre än den uppskattade',
  },
};

kycTriggerTexts[12] = {
  SE: {
    name: 'Omsättning de senaste sex månaderna är högre än den uppskattade',
  },
};

kycTriggerTexts[13] = {
  SE: {
    name: 'Omsättning de senaste tolv månaderna är högre än den uppskattade',
  },
};

kycTriggerTexts[14] = {
  SE: {
    name: 'Resultat de senaste tre månaderna är lägre än det uppskattade',
  },
};

kycTriggerTexts[15] = {
  SE: {
    name: 'Resultat de senaste sex månaderna är lägre än det uppskattade',
  },
};

kycTriggerTexts[16] = {
  SE: {
    name: 'Resultat de senaste tolv månaderna är lägre än det uppskattade',
  },
};

kycTriggerTexts[17] = {
  SE: {
    name: 'Resultat de senaste tre månaderna är högre än det uppskattade',
  },
};

kycTriggerTexts[18] = {
  SE: {
    name: 'Resultat de senaste sex månaderna är högre än det uppskattade',
  },
};

kycTriggerTexts[19] = {
  SE: {
    name: 'Resultat de senaste tolv månaderna är högre än det uppskattade',
  },
};

kycTriggerTexts[20] = {
  SE: {
    name: 'Månadsvis omsättning är lägre än den uppskattade',
  },
};

kycTriggerTexts[21] = {
  SE: {
    name: 'Månadsvis omsättning är högre än den uppskattade',
  },
};

kycTriggerTexts[22] = {
  SE: {
    name: 'Summa kontanter i bokföringen högre än uppskattat',
  },
};

kycTriggerTexts[23] = {
  SE: {
    name: `Bolaget redovisar kontanter trots ett 'nej' i formuläret`,
  },
};

kycTriggerTexts[24] = {
  SE: {
    name: 'Månadsvis omsättning via kreditinstitut är högre än den uppskattade',
  },
};

kycTriggerTexts[25] = {
  SE: {
    name: 'Månadsvis omsättning via kreditinstitut är lägre än den uppskattade',
  },
};

kycTriggerTexts[26] = {
  SE: {
    name: 'Genomsnittlig fakturering är högre än den uppskattade',
  },
};

kycTriggerTexts[27] = {
  SE: {
    name: 'Genomsnittlig fakturering är lägre än den uppskattade',
  },
};

kycTriggerTexts[28] = {
  SE: {
    name: 'Antal transaktioner är lägre än uppskattat',
  },
};

kycTriggerTexts[29] = {
  SE: {
    name: 'Antal transaktioner är högre än uppskattat',
  },
};

kycTriggerTexts[30] = {
  SE: {
    name: 'Kunde inte matcha angivet konto',
  },
};

kycTriggerTexts[31] = {
  SE: {
    name: 'Bolaget har en potentiellt skadlig hemsida',
  },
};

kycTriggerTexts[32] = {
  SE: {
    name: 'Bolagets e-handelsplattform har en potentiellt skadlig hemsida',
  },
};

kycTriggerTexts[33] = {
  SE: {
    name: `Bolaget redovisar inga kontanter trots ett 'ja' i formuläret`,
  },
};

export function GetKYCTriggerTexts(id) {
  return {
    id,
    texts: kycTriggerTexts[id].SE,
  };
}

export function TranslateKYCTriggers(kycTriggerIds) {
  const translated = [];
  for (let i = 0; i < kycTriggerIds.length; i += 1) {
    if (kycTriggerTexts[kycTriggerIds[i]] !== undefined) {
      translated.push(GetKYCTriggerTexts(kycTriggerIds[i]));
    } else {
      translated.push({
        id: kycTriggerIds[i],
        texts: { name: `ID: ${kycTriggerIds[i]} Beskrivning saknas.` },
      });
    }
  }
  return translated;
}
