/* eslint-disable react/prop-types */
import './TabNav.scss';

export default function TabNav({ children }) {
  return (
    <div className="tab-nav-wrapper">
      <div className="tab-nav-content">{children}</div>
    </div>
  );
}

TabNav.Tab = function Tab({ title, onClick, active }) {
  return (
    <button
      className={`${active ? 'active-tab' : 'inactive-tab'}`}
      type="button"
      onClick={onClick}
    >
      <p>{title}</p>
    </button>
  );
};
