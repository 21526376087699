import './ArrowTab.scss';
/* eslint-disable react/prop-types */

export default function ArrowTab({ text, inactive, color }) {
  return (
    <div className="arrow-tab-wrapper">
      <div className="arrow-tab-mask">
        <div
          className="arrow-tab-outline"
          style={{ filter: `invert(${inactive ? '0.3' : '0.7'})` }}
        />
        <div
          className="arrow-tab-content"
          style={{
            backgroundImage: `linear-gradient(white, ${color}${
              inactive ? '00' : '30'
            })`,
            opacity: `${inactive ? '60%' : ''}`,
          }}
        >
          <p className="text" style={inactive ? { opacity: '60%' } : { color }}>
            {text}
          </p>
        </div>
      </div>
    </div>
  );
}
