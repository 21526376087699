import './Customers.scss';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faAngleDoubleDown,
  faAngleDoubleUp,
} from '@fortawesome/free-solid-svg-icons';
import CustomerService from '../../../../Services/CustomerService';
import UserService from '../../../../Services/UserService';
import XpektorButton from '../../../../Components/XpektorButton/XpektorButton';
import ChangeLogoModal from '../Modals/ChangeLogoModal';
import CustomerUsersModal from '../Modals/CustomerUsersModal';
import CustomerClientsModal from '../Modals/CustomerClientsModal';
import CreateCustomerModal from '../Modals/CreateCustomerModal';
import CustomerHistoryModal from '../Modals/CustomerHistoryModal';
import LoadingBar from '../../../../Components/LoadingBar/LoadingBar';
import Searchbar from '../../../../Components/Searchbar/Searchbar';
/* eslint-disable react/prop-types */

function CustomerEntry({
  customer,
  subCustomers,
  onChangeLogoClick,
  onUsersClick,
  onClientsClick,
  onHistoryClick,
  onShowSubCustomersClick,
}) {
  const [showSubCustomers, setShowSubCustomers] = useState(false);
  const [fetchingClients, setFetchingClients] = useState(false);
  const [fetchingUsers, setFetchingUsers] = useState(false);
  const [fetchingHistory, setFetchingHistory] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredSubCustomers, setFilteredSubCustomers] = useState([]);

  useEffect(() => {
    const newFilteredMainCustomers =
      searchTerm.length > 0
        ? subCustomers.filter((subCustomer) => {
            return (
              subCustomer.name
                ?.toLowerCase()
                .includes(searchTerm?.toLowerCase()) ||
              subCustomer.organizationNumber
                .toString()
                .includes(searchTerm?.toLowerCase())
            );
          })
        : subCustomers;
    setFilteredSubCustomers(newFilteredMainCustomers);
  }, [searchTerm, subCustomers]);

  return (
    <div className="customer-entry">
      <div className="customer-entry-card">
        <div className="content-column">
          <div className="title-row">
            <p className="title">{customer.name}</p>
            <div
              className={`tag ${
                customer.customerType === 1
                  ? 'advice'
                  : customer.customerType === 2
                  ? 'finance'
                  : customer.customerType === 3
                  ? 'subsegment'
                  : ''
              }`}
            >
              <p>
                {customer.customerType === 1
                  ? 'Advice'
                  : customer.customerType === 2
                  ? 'Finance'
                  : customer.customerType === 3
                  ? 'Subsegment'
                  : 'N/A'}
              </p>
            </div>
          </div>
          <div className="data-content-row">
            <div className="data-content-column">
              <div className="data-column">
                <p>
                  Org. nummer: <b>{customer.organizationNumber ?? '-'}</b>
                </p>
                <p>
                  Kontaktperson: <b>{customer.contactPerson ?? '-'}</b>
                </p>
                <p>
                  Faktureringsmail: <b>{customer.billingEmail ?? '-'}</b>
                </p>
                <p>
                  Telefonnummer: <b>{customer.phoneNumber ?? '-'}</b>
                </p>
              </div>
              <div className="gap-row">
                <XpektorButton
                  secondary
                  content={`Användare (${customer.userCount})`}
                  onClick={() => {
                    setFetchingUsers(true);
                    onUsersClick(customer)
                      .then(() => {})
                      .catch(() => {})
                      .finally(() => {
                        setFetchingUsers(false);
                      });
                  }}
                  loading={fetchingUsers}
                />
                <XpektorButton
                  secondary
                  content={`Klienter (${customer.clientCount})`}
                  onClick={() => {
                    setFetchingClients(true);
                    onClientsClick(customer)
                      .then(() => {})
                      .catch(() => {})
                      .finally(() => {
                        setFetchingClients(false);
                      });
                  }}
                  loading={fetchingClients}
                />
              </div>
            </div>
            <div className="data-content-column">
              <div className="data-column">
                <p>
                  Länkar denna månad:{' '}
                  <b>{customer.onboardingCreatedThisMonth ?? 0}</b>
                </p>
                <p>
                  KYC denna månad: <b>{customer.kycCreatedThisMonth ?? 0}</b>
                </p>
              </div>
              <div className="gap-row">
                <XpektorButton
                  secondary
                  content="Aktivitet"
                  onClick={() => {
                    setFetchingHistory(true);
                    onHistoryClick(customer)
                      .then(() => {})
                      .catch(() => {})
                      .finally(() => {
                        setFetchingHistory(false);
                      });
                  }}
                  loading={fetchingHistory}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="logo-column">
          {customer.logo ? (
            <img className="logo" src={customer.logo} alt="Logo" />
          ) : (
            <div className="missing-logo">
              <p>Logga saknas</p>
            </div>
          )}
          <XpektorButton
            content={customer.logo ? 'Ändra' : 'Ladda upp'}
            secondary
            onClick={() => onChangeLogoClick(customer)}
          />
        </div>
      </div>
      {customer.subCustomerCount > 0 ? (
        <>
          {showSubCustomers && subCustomers ? (
            <div className="sub-customer-container">
              <div className="sub-customer-title-column">
                <Searchbar
                  placeholder="Sök sub-customer"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className="customer-list sub-customer-list">
                {filteredSubCustomers && filteredSubCustomers.length > 0 ? (
                  filteredSubCustomers.map((subCustomer) => {
                    return (
                      <CustomerEntry
                        customer={subCustomer}
                        key={subCustomer.id}
                        onChangeLogoClick={onChangeLogoClick}
                        onUsersClick={onUsersClick}
                        onClientsClick={onClientsClick}
                        onHistoryClick={onHistoryClick}
                      />
                    );
                  })
                ) : (
                  <p className="no-hits-item">Inga sökresultat</p>
                )}
              </div>
            </div>
          ) : (
            ''
          )}
          <button
            type="button"
            className="sub-customer-tab"
            onClick={() => {
              if (!showSubCustomers) {
                setSearchTerm('');
                onShowSubCustomersClick();
              }
              setShowSubCustomers(!showSubCustomers);
            }}
          >
            <p>{`${showSubCustomers ? 'Dölj' : 'Visa'} sub-customers (${
              customer.subCustomerCount
            })`}</p>
            <FontAwesomeIcon
              className="expand-icon"
              icon={showSubCustomers ? faAngleDoubleUp : faAngleDoubleDown}
            />
          </button>
        </>
      ) : (
        ''
      )}
    </div>
  );
}

function CustomerTable({
  mainCustomers,
  subCustomers,
  onChangeLogoClick,
  onUsersClick,
  onClientsClick,
  onHistoryClick,
  onShowSubCustomersClick,
  searchTerm,
}) {
  const [filteredMainCustomers, setFilteredMainCustomers] = useState([]);

  useEffect(() => {
    const newFilteredMainCustomers =
      searchTerm.length > 0
        ? mainCustomers.filter((customer) => {
            return (
              customer.name
                ?.toLowerCase()
                .includes(searchTerm?.toLowerCase()) ||
              customer.organizationNumber
                .toString()
                .includes(searchTerm?.toLowerCase())
            );
          })
        : mainCustomers;
    setFilteredMainCustomers(newFilteredMainCustomers);
  }, [searchTerm, mainCustomers]);

  return (
    <div className="customer-table-container">
      <div className="customer-table-header" />
      <div className="customer-list">
        {filteredMainCustomers.length > 0 ? (
          filteredMainCustomers
            .sort((a, b) => {
              const stringA = a.name.toUpperCase();
              const stringB = b.name.toUpperCase();
              return stringA < stringB ? -1 : stringA > stringB ? 1 : 0;
            })
            .map((customer) => {
              return (
                <CustomerEntry
                  customer={customer}
                  subCustomers={
                    subCustomers[customer.id]
                      ? subCustomers[customer.id].sort((a, b) => {
                          const stringA = a.name.toUpperCase();
                          const stringB = b.name.toUpperCase();
                          return stringA < stringB
                            ? -1
                            : stringA > stringB
                            ? 1
                            : 0;
                        })
                      : []
                  }
                  onShowSubCustomersClick={() =>
                    onShowSubCustomersClick(customer)
                  }
                  key={customer.id}
                  onChangeLogoClick={onChangeLogoClick}
                  onUsersClick={onUsersClick}
                  onClientsClick={onClientsClick}
                  onHistoryClick={onHistoryClick}
                />
              );
            })
        ) : (
          <p className="no-hits-item">Inga sökresultat</p>
        )}
      </div>
    </div>
  );
}

export default function Customers() {
  const [fetchedData, setFetchedData] = useState(false);
  const [mainCustomers, setMainCustomers] = useState([]);
  const [showChangeLogoModal, setShowChangeLogoModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});

  const [showUsersModal, setShowUsersModal] = useState(false);
  const [showClientsModal, setShowClientsModal] = useState(false);
  const [showCreateCustomerModal, setShowCreateCustomerModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);

  const [fetchedUserLists, setFetchedUserLists] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [fetchedClientLists, setFetchedClientLists] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);

  const [fetchedStatisticsLists, setFetchedStatisticsLists] = useState([]);
  const [selectedStatistics, setSelectedStatistics] = useState([]);

  const [fetchedActionLists, setFetchedActionLists] = useState([]);
  const [selectedActions, setSelectedActions] = useState([]);

  const [fetchedSubCustomerLists, setFetchedSubCustomerLists] = useState([]);
  const [currentUser, setCurrentUser] = useState({});

  const fetchSubCustomers = async (customerId) => {
    await CustomerService.getSubCustomers(customerId)
      .then((response) => {
        const newFetchedSubCustomerLists = { ...fetchedSubCustomerLists };
        newFetchedSubCustomerLists[customerId] = response.data;
        setFetchedSubCustomerLists(newFetchedSubCustomerLists);

        const mainCustomerIndex = mainCustomers.findIndex(
          (c) => c.id === customerId
        );

        if (mainCustomerIndex !== -1) {
          const newMainCustomerList = [...mainCustomers];
          newMainCustomerList[mainCustomerIndex].subCustomerCount =
            response.data.length;
          setMainCustomers(newMainCustomerList);
        }
      })
      .catch(() => {});
  };

  const onCustomerCreated = async (newCustomer) => {
    if (newCustomer.mainCustomerId) {
      await fetchSubCustomers(newCustomer.mainCustomerId);
    } else {
      await CustomerService.getMainCustomers()
        .then((response) => {
          setMainCustomers(response.data);
        })
        .catch(() => {});
    }
  };

  const onUserCreatedOrDeleted = async (customer) => {
    await UserService.getCustomerUsers(customer.id)
      .then((response) => {
        const newFetchedUserLists = { ...fetchedUserLists };
        newFetchedUserLists[customer.id] = response.data;
        setFetchedUserLists(newFetchedUserLists);
        setSelectedUsers(response.data);

        if (customer.mainCustomerId) {
          const customerIndex = fetchedSubCustomerLists[
            customer.mainCustomerId
          ].findIndex((c) => c.id === customer.id);

          if (customerIndex !== -1) {
            const newFetchedSubCustomerLists = { ...fetchedSubCustomerLists };
            newFetchedSubCustomerLists[customer.mainCustomerId][
              customerIndex
            ].userCount = response.data.length;
            setFetchedSubCustomerLists(newFetchedSubCustomerLists);
          }
        } else {
          const customerIndex = mainCustomers.findIndex(
            (c) => c.id === customer.id
          );

          if (customerIndex !== -1) {
            const newMainCustomerList = [...mainCustomers];
            newMainCustomerList[customerIndex].userCount = response.data.length;
            setMainCustomers(newMainCustomerList);
          }
        }
      })
      .catch(() => {
        setSelectedUsers([]);
      });
  };

  const onLogoChanged = async (customer) => {
    if (customer.mainCustomerId) {
      await fetchSubCustomers(customer.mainCustomerId);
    } else {
      await CustomerService.getMainCustomers()
        .then((response) => {
          setMainCustomers(response.data);
        })
        .catch(() => {});
    }
  };

  const getCustomerUsers = async (customer) => {
    setSelectedCustomer(customer);

    if (fetchedUserLists[customer.id] !== undefined) {
      setSelectedUsers(fetchedUserLists[customer.id]);
      return;
    }

    await UserService.getCustomerUsers(customer.id)
      .then((response) => {
        const newFetchedUserLists = { ...fetchedUserLists };
        newFetchedUserLists[customer.id] = response.data;
        setFetchedUserLists(newFetchedUserLists);
        setSelectedUsers(response.data);
      })
      .catch(() => {
        setSelectedUsers([]);
      });
  };

  const onCustomerUsersClick = async (customer) => {
    await getCustomerUsers(customer)
      .then(() => setShowUsersModal(true))
      .catch(() => {});
  };

  const getCustomerClientData = async (customer) => {
    if (fetchedClientLists[customer.id] !== undefined) {
      setSelectedClients(fetchedClientLists[customer.id]);
    } else {
      await CustomerService.getCustomerClients(customer.id)
        .then((response) => {
          const newFetchedClientLists = { ...fetchedClientLists };
          newFetchedClientLists[customer.id] = response.data;
          setFetchedClientLists(newFetchedClientLists);
          setSelectedClients(response.data);
        })
        .catch(() => {
          setSelectedClients([]);
        });
    }

    if (fetchedStatisticsLists[customer.id] !== undefined) {
      setSelectedStatistics(fetchedStatisticsLists[customer.id]);
    } else {
      await CustomerService.getCustomerStatistics(customer.id)
        .then((response) => {
          const newFetchedStatisticsLists = { ...fetchedStatisticsLists };
          newFetchedStatisticsLists[customer.id] = response.data;
          setFetchedStatisticsLists(newFetchedStatisticsLists);
          setSelectedStatistics(response.data);
        })
        .catch(() => {
          setSelectedStatistics([]);
        });
    }
  };

  const onClientsClick = async (customer) => {
    setSelectedCustomer(customer);

    await getCustomerClientData(customer)
      .then(() => setShowClientsModal(true))
      .catch(() => {});
  };

  const getCustomerHistory = async (customer) => {
    if (fetchedActionLists[customer.id] !== undefined) {
      setSelectedActions(fetchedActionLists[customer.id]);
      return;
    }

    await CustomerService.getBillingActions(customer.id)
      .then((response) => {
        const newFetchedActionLists = { ...fetchedActionLists };
        newFetchedActionLists[customer.id] = response.data;
        setFetchedActionLists(newFetchedActionLists);
        setSelectedActions(response.data);
      })
      .catch(() => {
        setSelectedActions([]);
      });
  };

  const onHistoryClick = async (customer) => {
    setSelectedCustomer(customer);

    await getCustomerHistory(customer)
      .then(() => setShowHistoryModal(true))
      .catch(() => {});
  };

  const openChangeLogoModal = (customer) => {
    setSelectedCustomer(customer);
    setShowChangeLogoModal(true);
  };

  useEffect(() => {
    const getData = async () => {
      await CustomerService.getMainCustomers()
        .then((response) => {
          setMainCustomers(response.data);
        })
        .catch(() => {})
        .finally(() => setFetchedData(true));

      setCurrentUser(UserService.getCurrentUser());
    };

    getData();
  }, []);

  const [searchTerm, setSearchTerm] = useState('');

  return (
    <div className="system-admin-customers-wrapper">
      <LoadingBar active={!fetchedData} status="Hämtar kunder..." />

      <div className="nav-row">
        <Searchbar
          placeholder="Sök kund"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <XpektorButton
          icon={faPlus}
          content="Skapa kund"
          onClick={() => setShowCreateCustomerModal(true)}
        />
      </div>
      <CustomerTable
        searchTerm={searchTerm}
        mainCustomers={mainCustomers}
        subCustomers={fetchedSubCustomerLists}
        onChangeLogoClick={openChangeLogoModal}
        onUsersClick={(clickedCustomer) =>
          onCustomerUsersClick(clickedCustomer)
        }
        onClientsClick={(clickedCustomer) => onClientsClick(clickedCustomer)}
        onHistoryClick={(clickedCustomer) => onHistoryClick(clickedCustomer)}
        onShowSubCustomersClick={(clickedCustomer) => {
          if (!fetchedSubCustomerLists[clickedCustomer.id]) {
            fetchSubCustomers(clickedCustomer.id);
          }
        }}
      />
      {showChangeLogoModal ? (
        <ChangeLogoModal
          customer={selectedCustomer}
          handleClose={() => {
            setShowChangeLogoModal(false);
          }}
          onLogoUpdate={() => {
            onLogoChanged(selectedCustomer)
              .then(() => {
                setShowChangeLogoModal(false);
              })
              .catch(() => {});
          }}
        />
      ) : (
        ''
      )}
      {showUsersModal ? (
        <CustomerUsersModal
          users={selectedUsers}
          handleClose={() => setShowUsersModal(false)}
          customer={selectedCustomer}
          onUserCreatedOrDeleted={() =>
            onUserCreatedOrDeleted(selectedCustomer)
          }
          currentUser={currentUser}
        />
      ) : (
        ''
      )}
      {showClientsModal ? (
        <CustomerClientsModal
          clients={selectedClients}
          handleClose={() => setShowClientsModal(false)}
          customerName={selectedCustomer.name ?? 'N/A'}
          statistics={selectedStatistics}
        />
      ) : (
        ''
      )}
      {showCreateCustomerModal ? (
        <CreateCustomerModal
          handleClose={() => setShowCreateCustomerModal(false)}
          mainCustomers={mainCustomers}
          onCustomerCreated={(createdCustomer) =>
            onCustomerCreated(createdCustomer).then(() => {
              setShowCreateCustomerModal(false);
              getCustomerUsers(createdCustomer)
                .then(() => setShowUsersModal(true))
                .catch(() => {});
            })
          }
        />
      ) : (
        ''
      )}
      {showHistoryModal ? (
        <CustomerHistoryModal
          actions={selectedActions}
          customerName={selectedCustomer.name ?? 'N/A'}
          handleClose={() => setShowHistoryModal(false)}
        />
      ) : (
        ''
      )}
    </div>
  );
}
