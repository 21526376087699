import './XpektorBootstrapModal.scss';
import './ScreeningResultModal.scss';
import { useState } from 'react';
import Moment from 'moment';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

/* eslint-disable react/prop-types */
function SearchHit({ searchHit }) {
  const [expanded, setExpanded] = useState(false);

  const AMLResultTypeTranslations = {
    0: 'Okategoriserad källa',
    1: 'PEP',
    2: 'PEP aktuell',
    3: 'PEP tidigare',
    4: 'PEP länkad',
    5: 'Sanktion',
    6: 'Sanktion aktuell',
    7: 'Sanktion tidigare',
    8: 'Negativ media',
    9: 'Profil av intresse',
    10: 'Efterlevnad',
    11: 'SOE',
    12: 'SOE aktuell',
    13: 'SOE tidigare',
  };

  function TranslateResultType(typeArray) {
    if (typeArray.length === 0) return 'Okategoriserad källa';

    let newString = '';
    for (let i = 0; i < typeArray.length; i += 1) {
      newString = `${newString} ${AMLResultTypeTranslations[typeArray[i]]}`;

      if (i < typeArray.length - 1) {
        newString = `${newString},`;
      }
    }
    return newString;
  }

  return (
    <div className="search-hit-wrapper">
      <button
        type="button"
        onClick={() => setExpanded(!expanded)}
        className="hit-title-button"
      >
        <p className="hit-title">
          Träff på &quot;<b>{searchHit.name}&quot;</b>
        </p>
        <FontAwesomeIcon
          className="expand-icon"
          icon={expanded ? faChevronDown : faChevronRight}
        />
      </button>
      <div className="source-list">
        {searchHit.sources.map((source, sourceIndex) => {
          return (
            <div
              className={`source-wrapper ${expanded ? '' : 'source-hidden'}`}
              key={source.id}
            >
              <p className="source-title">
                <b>
                  {sourceIndex + 1}.{' '}
                  {TranslateResultType(source.kycamlResultType)}
                </b>
              </p>
              <p>
                <b>Titel:</b>{' '}
                {source.title !== null && source.title.length > 0
                  ? source.title.replaceAll('\u00A0', ' ')
                  : '-'}
              </p>
              <p>
                <b>Beskrivning: </b>
                {source.description !== null && source.description.length > 0
                  ? source.description.replaceAll('\u00A0', ' ')
                  : '-'}
              </p>
              <p>
                <b>Länk: </b>
                {source.link ? (
                  <a
                    href={source.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {source.link}
                  </a>
                ) : (
                  '-'
                )}
              </p>
              <p>
                <b>Dokument: </b>
                {source.documentLink ? (
                  <a
                    href={source.documentLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {source.documentLink}
                  </a>
                ) : (
                  '-'
                )}
              </p>
              <p>
                <b>Datum registrerat: </b>
                {source.dateAdded
                  ? Moment.utc(source.dateAdded).local().format('YYYY-MM-DD')
                  : '-'}
              </p>
              <p>
                <b>Datum publicerat: </b>
                {source.datePublished
                  ? Moment.utc(source.datePublished)
                      .local()
                      .format('YYYY-MM-DD')
                  : '-'}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default function ScreeningResultModal({ handleClose, result }) {
  return (
    <Modal
      size="lg"
      show
      onHide={handleClose}
      backdrop="static"
      centered
      keyboard={false}
      className="border-0 screening-result-modal xpektor-bootstrap-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Screening resultat för <b>{result.name}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="screening-result-wrapper">
          {result.searchHits.map((searchHit) => {
            return <SearchHit searchHit={searchHit} key={searchHit.id} />;
          })}
        </div>
      </Modal.Body>
    </Modal>
  );
}
