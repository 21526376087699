/* eslint-disable react/prop-types */
import './EditablePanel.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faXmark,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { useState } from 'react';

export default function EditablePanel({
  children,
  title,
  editMode,
  onEditClick,
  onConfirmClick,
  onCancelClick,
}) {
  const [editHover, setEditHover] = useState(false);
  const [confirmHover, setConfirmHover] = useState(false);
  const [cancelHover, setCancelHover] = useState(false);

  const renderEditIcon = () => {
    return (
      <FontAwesomeIcon
        icon={faPenToSquare}
        className={`edit-icon ${editHover ? 'edit-hover' : ''}`}
        onMouseEnter={() => {
          setEditHover(true);
        }}
        onMouseLeave={() => {
          setEditHover(false);
        }}
        onClick={() => {
          onEditClick();
          setCancelHover(true);
        }}
      />
    );
  };

  const renderConfirmCancelIcons = () => {
    return (
      <>
        <FontAwesomeIcon
          icon={faCheck}
          className={`confirm-icon ${
            confirmHover ? 'confirm-cancel-hover' : ''
          }`}
          onMouseEnter={() => {
            setConfirmHover(true);
          }}
          onMouseLeave={() => {
            setConfirmHover(false);
          }}
          onClick={() => {
            onConfirmClick();
            setConfirmHover(false);
          }}
        />
        <FontAwesomeIcon
          icon={faXmark}
          className={`cancel-icon ${cancelHover ? 'confirm-cancel-hover' : ''}`}
          onMouseEnter={() => {
            setCancelHover(true);
          }}
          onMouseLeave={() => {
            setCancelHover(false);
          }}
          onClick={() => {
            onCancelClick();
            setConfirmHover(false);
          }}
        />
      </>
    );
  };

  return (
    <div className="editable-panel-wrapper">
      <div className="editable-panel-title-container">
        <p>{title}</p>
        {editMode ? renderConfirmCancelIcons() : renderEditIcon()}
      </div>
      <div className="panel-content">{children}</div>
    </div>
  );
}

EditablePanel.Entry = function Entry({
  label,
  value,
  inputValue,
  onInputChange,
  editMode,
  infoText,
  type,
}) {
  return (
    <div className="editable-entry-container">
      <label htmlFor={label}>{label}</label>
      <input
        id={label}
        type={type}
        className={editMode ? 'editable-value' : 'value'}
        value={editMode ? inputValue : value ?? ''}
        onChange={(e) => onInputChange(e)}
        placeholder="-"
        disabled={!editMode}
      />
      <div className="input-info-container">
        {infoText ? (
          <>
            <FontAwesomeIcon className="input-info-icon" icon={faCircleInfo} />
            <p>{infoText}</p>
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

EditablePanel.ImageEntry = function ImageEntry() {
  /* {
  label,
  value,
  inputValue,
  onInputChange,
  infoText, 
  } */
  return <div className="editable-image-entry-container" />;
};
