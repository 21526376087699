/* eslint-disable react/prop-types */
import HoverableText from '../../../../Components/HoverableText/HoverableText';
import './Module.scss';

export default function Module({ children }) {
  return <div className="module-wrapper">{children}</div>;
}

Module.KeyFigureSection = function KeyFigureSection({ children }) {
  return <div className="key-figures-section">{children}</div>;
};

Module.KeyFigure = function KeyFigure({
  title,
  value,
  valueType,
  currency,
  subTitle,
  info,
}) {
  let formattedValue = '';
  let formattedSign = '';

  if (valueType === 'currency') {
    formattedValue = Number(Number(value).toFixed(0))
      .toLocaleString()
      .replaceAll(',', ' ');
    formattedSign = currency ?? 'kr';
  } else if (valueType === 'percentage') {
    formattedValue = Number(value)
      .toFixed(0)
      .toLocaleString()
      .replaceAll(',', ' ');
    formattedSign = '%';
  } else {
    formattedValue = value;
    formattedSign = valueType;
  }

  return (
    <div className="key-figure-container">
      <div className="key-figure-content">
        <HoverableText styleClass="key-figure-title" text={title} info={info} />
        <div className="key-figure-value">
          <p className="value">{formattedValue}</p>
          <p className="value-sign">{formattedSign}</p>
        </div>
        <p className="key-figure-subtitle">{subTitle}</p>
      </div>
    </div>
  );
};

Module.BankAccountOverview = function BankAccountOverview({
  dropdown,
  selectedAccount,
  selectedBalanceAvailable,
  selectedAccountNumber,
  currencies,
}) {
  return (
    <div className="bank-account-overview">
      <div className="title-row">{dropdown}</div>
      <div className="content-row">
        <div className="entry-column">
          <p className="entry-title">
            {selectedAccount === 'all' ? 'Totalt saldo' : 'Saldo'}
          </p>
          <div className="value-currency-row">
            <p className="value">
              {selectedBalanceAvailable.currency !== 'mixed'
                ? Number(Number(selectedBalanceAvailable.amount).toFixed(0))
                    .toLocaleString()
                    .replaceAll(',', ' ')
                : 'Blandade valutor'}
            </p>
            <p className="currency">
              {selectedBalanceAvailable.currency !== 'mixed'
                ? selectedBalanceAvailable.currency
                : ''}
            </p>
          </div>
        </div>

        <div className="entry-column">
          {selectedAccount === 'all' ? (
            <>
              <p className="entry-title">Hittade valutor</p>
              <p className="value">
                {currencies.map((currency, index) => {
                  if (index < currencies.length - 1) {
                    return `${currency}, `;
                  }
                  return `${currency}`;
                })}
              </p>
            </>
          ) : Object.keys(selectedAccountNumber).length !== 0 ? (
            <>
              <p className="entry-title">{selectedAccountNumber.scheme}</p>
              <p className="value">{selectedAccountNumber.id}</p>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

Module.DataSection = function DataSection({ children }) {
  return <div className="data-section">{children}</div>;
};

Module.DataColumn = function DataColumn({ children }) {
  return <div className="data-column">{children}</div>;
};

Module.Nav = function Nav({ children }) {
  return <div className="nav-bar">{children}</div>;
};

Module.TransactionContent = function TransactionContent({ children }) {
  return <div className="transaction-content">{children}</div>;
};

Module.ReportSection = function ReportSection({ vertical, children }) {
  return (
    <div className={vertical ? 'reports-section-vertical' : 'reports-section'}>
      {children}
    </div>
  );
};

Module.SectionTitle = function SectionTitle({ text }) {
  return <h2 className="section-title">{text}</h2>;
};
