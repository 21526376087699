import './CustomerUsersModal.scss';
import './SystemAdminModalTable.scss';
import { useState } from 'react';
import Moment from 'moment';
import isEmail from 'validator/lib/isEmail';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortUp,
  faSortDown,
  faPlus,
  faCheck,
  faXmark,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import UserService from '../../../../Services/UserService';
import {
  XpektorForm,
  FormInput,
  FormDropdown,
} from '../../../../Components/XpektorForms/XpektorForms';
import XpektorButton from '../../../../Components/XpektorButton/XpektorButton';

/* eslint-disable react/prop-types */

function UserItem({ user, onDelete, canBeDeleted }) {
  const [confirmRemove, setConfirmRemove] = useState(false);
  const [removing, setRemoving] = useState(false);

  return (
    <div className="table-item-row user-table-row">
      <p>{user.name}</p>
      <p>{user.email}</p>
      <p>
        {user.role === 0
          ? 'Användare'
          : user.role === 1
          ? 'Admin'
          : user.role === 2
          ? 'Integrerad'
          : user.role === 3
          ? 'Integratör'
          : user.role === 10
          ? 'System Admin'
          : 'N/A'}
      </p>
      {confirmRemove ? (
        <div className="confirm-action-content">
          {removing ? (
            <FontAwesomeIcon className="loading-icon" icon={faSpinner} />
          ) : (
            <>
              <p>Ta bort användare?</p>
              <button
                type="button"
                className="confirm"
                onClick={() => {
                  setRemoving(true);
                  onDelete()
                    .then(() => {
                      setConfirmRemove(false);
                      setRemoving(false);
                    })
                    .catch(() => {});
                }}
              >
                <p>Ta bort</p>
              </button>
              <button
                type="button"
                className="cancel"
                onClick={() => setConfirmRemove(false)}
              >
                <p>Avbryt</p>
              </button>
            </>
          )}
        </div>
      ) : (
        <div className="user-table-dynamic-content">
          <p>{user.accountVerified ? 'Ja' : 'Nej'}</p>
          <p>
            {user.created
              ? Moment(user.created).format('YYYY-MM-DD HH:mm')
              : 'N/A'}
          </p>
          <p>
            {user.lastLogin
              ? Moment(user.lastLogin).format('YYYY-MM-DD HH:mm')
              : 'Aldrig'}
          </p>
          {canBeDeleted ? (
            <FontAwesomeIcon
              className="action-icon-button centered-content"
              icon={faTrashCan}
              onClick={() => setConfirmRemove(true)}
            />
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  );
}

function TableHeaderItem({ text, onClick, orderIcon }) {
  return (
    <button
      type="button"
      className={`table-head-button ${!onClick ? 'not-sortable' : ''}`}
      onClick={() => {
        if (onClick) onClick();
      }}
      disabled={!onClick}
    >
      <p> {text}</p>
      {onClick ? (
        <FontAwesomeIcon className="sort-icon" icon={orderIcon ?? faSort} />
      ) : (
        ''
      )}
    </button>
  );
}

/* eslint-disable jsx-a11y/no-autofocus */
export default function CustomerUsersModal({
  users,
  handleClose,
  customer,
  onUserCreatedOrDeleted,
  currentUser,
}) {
  const [newUser, setNewUser] = useState({});
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');

  const [showNewUserItem, setShowNewUserItem] = useState(false);

  const requiredErrorText = 'Fältet kan inte vara tomt';
  const emailErrorText = 'Ej giltig email';
  const alreadyExistsErrorText = 'Användaren finns redan';

  const validateNewUser = () => {
    let newNameError = '';
    let newEmailError = '';

    if (newUser.name.length === 0) {
      newNameError = requiredErrorText;
    }
    if (newUser.email.length === 0) {
      newEmailError = requiredErrorText;
    } else if (!isEmail(newUser.email)) {
      newEmailError = emailErrorText;
    }

    setNameError(newNameError);
    setEmailError(newEmailError);

    if (newNameError.length > 0 || newEmailError.length > 0) return false;

    return true;
  };

  const createNewUser = async () => {
    if (!validateNewUser()) return;

    await UserService.CreateUser(newUser)
      .then(() => {
        onUserCreatedOrDeleted();
        setShowNewUserItem(false);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setEmailError(alreadyExistsErrorText);
        }
      })
      .finally(() => {});
  };

  const deleteUser = async (user) => {
    await UserService.DeleteUser(user.id)
      .then(() => {
        onUserCreatedOrDeleted();
      })
      .catch(() => {});
  };

  const [arrowIcon, setArrowIcon] = useState();
  const [currentOrdered, setCurrentOrdered] = useState('');
  const [order, setOrder] = useState('asc');

  const sortStringCompare = (a, b) => {
    const stringA = a?.toUpperCase();
    const stringB = b?.toUpperCase();

    if (stringA < stringB) {
      return -1;
    }
    if (stringA > stringB) {
      return 1;
    }
    return 0;
  };

  function SortByName() {
    if (order === 'asc') {
      users.sort((a, b) => {
        return sortStringCompare(a.name, b.name);
      });
      setOrder('desc');
    } else if (order === 'desc') {
      users.sort((a, b) => {
        return sortStringCompare(a.name, b.name) * -1;
      });
      setOrder('asc');
    }
  }

  function SortByEmail() {
    if (order === 'asc') {
      users.sort((a, b) => {
        return sortStringCompare(a.email, b.email);
      });
      setOrder('desc');
    } else if (order === 'desc') {
      users.sort((a, b) => {
        return sortStringCompare(a.email, b.email) * -1;
      });
      setOrder('asc');
    }
  }

  function SortByRole() {
    if (order === 'asc') {
      users.sort((a, b) => a.role - b.role);
      setOrder('desc');
    } else if (order === 'desc') {
      users.sort((a, b) => b.role - a.role);
      setOrder('asc');
    }
  }

  function SortByVerified() {
    if (order === 'asc') {
      users.sort((a, b) => a.accountVerified - b.accountVerified);
      setOrder('desc');
    } else if (order === 'desc') {
      users.sort((a, b) => b.accountVerified - a.accountVerified);
      setOrder('asc');
    }
  }

  function SortByCreatedDate() {
    if (order === 'asc') {
      users.sort((a, b) => new Date(b.created) - new Date(a.created));
      setOrder('desc');
    } else if (order === 'desc') {
      users.sort((a, b) => new Date(a.created) - new Date(b.created));
      setOrder('asc');
    }
  }

  function SortByLastLogin() {
    if (order === 'asc') {
      users.sort((a, b) => new Date(b.lastLogin) - new Date(a.lastLogin));
      setOrder('desc');
    } else if (order === 'desc') {
      users.sort((a, b) => new Date(a.lastLogin) - new Date(b.lastLogin));
      setOrder('asc');
    }
  }

  function OnSort(sortId) {
    setCurrentOrdered(sortId);
    setArrowIcon(order === 'asc' ? faSortUp : faSortDown);
  }

  return (
    <Modal
      show
      onHide={handleClose}
      backdrop="static"
      centered
      keyboard={false}
      className="border-0 xpektor-bootstrap-modal customer-users-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p>
            Användare under <b>{customer.name}</b>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showNewUserItem ? (
          <div className="new-user-item-row">
            <XpektorForm>
              <FormInput
                label="Namn"
                value={newUser.name}
                onChange={(e) => {
                  const changedNewUser = { ...newUser };
                  changedNewUser.name = e.target.value;
                  setNewUser(changedNewUser);

                  if (e.target.value.length === 0) {
                    setNameError(requiredErrorText);
                  }
                }}
                infoText={nameError}
                maxLength={100}
              />
              <FormInput
                label="Email"
                value={newUser.email}
                onChange={(e) => {
                  const changedNewUser = { ...newUser };
                  changedNewUser.email = e.target.value;
                  setNewUser(changedNewUser);

                  if (e.target.value.length === 0) {
                    setEmailError(requiredErrorText);
                  } else if (!isEmail(e.target.value)) {
                    setEmailError(emailErrorText);
                  } else {
                    setEmailError('');
                  }
                }}
                infoText={emailError}
                maxLength={100}
              />
              <FormDropdown
                defaultValue={1}
                label=""
                onChange={(e) => {
                  const changedNewUser = { ...newUser };
                  changedNewUser.role = Number(e.target.value);
                  setNewUser(changedNewUser);
                }}
              >
                <option value={0}>Användare</option>
                <option value={1}>Admin</option>
              </FormDropdown>
            </XpektorForm>
            <div className="action-row">
              <button type="button" className="confirm" onClick={createNewUser}>
                <FontAwesomeIcon icon={faCheck} className="icon" />
                <p>Skapa</p>
              </button>
              <button
                type="button"
                className="cancel"
                onClick={() => setShowNewUserItem(false)}
              >
                <FontAwesomeIcon icon={faXmark} className="icon" />
                <p>Avbryt</p>
              </button>
            </div>
          </div>
        ) : (
          <XpektorButton
            icon={faPlus}
            content="Skapa användare"
            secondary
            onClick={() => {
              setNewUser({
                customerId: customer.id,
                email: '',
                name: '',
                role: 1,
              });
              setNameError('');
              setEmailError('');
              setShowNewUserItem(true);
            }}
          />
        )}
        <div className="sysadmin-table sysadmin-user-table">
          <div className="user-table-row sysadmin-table-head">
            <TableHeaderItem
              text="Namn"
              onClick={() => {
                SortByName();
                OnSort('name');
              }}
              orderIcon={currentOrdered === 'name' ? arrowIcon : null}
            />
            <TableHeaderItem
              text="Email"
              onClick={() => {
                SortByEmail();
                OnSort('email');
              }}
              orderIcon={currentOrdered === 'email' ? arrowIcon : null}
            />
            <TableHeaderItem
              text="Användarroll"
              onClick={() => {
                SortByRole();
                OnSort('role');
              }}
              orderIcon={currentOrdered === 'role' ? arrowIcon : null}
            />
            <div className="user-table-dynamic-content">
              <TableHeaderItem
                text="Verifierad"
                onClick={() => {
                  SortByVerified();
                  OnSort('verified');
                }}
                orderIcon={currentOrdered === 'verified' ? arrowIcon : null}
              />
              <TableHeaderItem
                text="Skapad"
                onClick={() => {
                  SortByCreatedDate();
                  OnSort('created');
                }}
                orderIcon={currentOrdered === 'created' ? arrowIcon : null}
              />
              <TableHeaderItem
                text="Senast inloggad"
                onClick={() => {
                  SortByLastLogin();
                  OnSort('login');
                }}
                orderIcon={currentOrdered === 'login' ? arrowIcon : null}
              />
              <div />
            </div>
          </div>
          <div className="table-content">
            {users && users.length > 0 ? (
              users.map((user) => {
                return (
                  <UserItem
                    user={user}
                    key={user.id}
                    canBeDeleted={user.id !== currentUser.id}
                    onDelete={() => deleteUser(user)}
                  />
                );
              })
            ) : (
              <p className="no-items-item">Finns inga användare</p>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
