/* eslint-disable react/prop-types */
import './CompanyTable.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortUp,
  faSortDown,
  faLink,
  faLinkSlash,
} from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

function TableHeaderItem({ label, onClick, orderIcon, columnClass }) {
  return (
    <th className={columnClass ?? ''}>
      <button type="button" className="sortable" onClick={onClick}>
        {label}
        <FontAwesomeIcon icon={orderIcon ?? faSort} />
      </button>
    </th>
  );
}

export default function CompanyTable({
  children,
  order,
  sortByName,
  sortByScore,
  sortByKyc,
  sortByAccounting,
  sortByBank,
  sortBySkv,
}) {
  const [arrowIcon, setArrowIcon] = useState();
  const [currentOrdered, setCurrentOrdered] = useState('');

  function OnSort() {
    setArrowIcon(order === 'asc' ? faSortUp : faSortDown);
  }

  return (
    <table className="company-table">
      <thead>
        <tr>
          <TableHeaderItem
            label="Klientnamn"
            columnClass="name-column"
            onClick={() => {
              sortByName();
              OnSort();
              setCurrentOrdered('name');
            }}
            orderIcon={currentOrdered === 'name' ? arrowIcon : null}
          />
          <TableHeaderItem
            label="Score (BETA)"
            columnClass="grade-column"
            onClick={() => {
              sortByScore();
              OnSort();
              setCurrentOrdered('score');
            }}
            orderIcon={currentOrdered === 'score' ? arrowIcon : null}
          />
          <TableHeaderItem
            label="KYC"
            columnClass="kyc-column"
            onClick={() => {
              sortByKyc();
              OnSort();
              setCurrentOrdered('kyc');
            }}
            orderIcon={currentOrdered === 'kyc' ? arrowIcon : null}
          />
          <TableHeaderItem
            label="Bokföring"
            columnClass="connection-column"
            onClick={() => {
              sortByAccounting();
              OnSort();
              setCurrentOrdered('accounting');
            }}
            orderIcon={currentOrdered === 'accounting' ? arrowIcon : null}
          />
          <TableHeaderItem
            label="Bank"
            columnClass="connection-column"
            onClick={() => {
              sortByBank();
              OnSort();
              setCurrentOrdered('bank');
            }}
            orderIcon={currentOrdered === 'bank' ? arrowIcon : null}
          />
          <TableHeaderItem
            label="Skatteverket"
            columnClass="connection-column"
            onClick={() => {
              sortBySkv();
              OnSort();
              setCurrentOrdered('skv');
            }}
            orderIcon={currentOrdered === 'skv' ? arrowIcon : null}
          />
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
}

CompanyTable.Item = function Item({ client, onClick }) {
  return (
    <tr className="company-item-wrapper" onClick={onClick}>
      <td className="name-column">{client.name}</td>
      <td className="grade-column">
        <p
          className={`grade-icon ${
            !client.externalConnectionActive && !client.bankConnectionActive
              ? 'grade-icon-disconnected grade--'
              : `grade-${client.scoreGrade ?? '-'}`
          } `}
        >
          {client.scoreGrade ?? '-'}
        </p>
      </td>
      <td className="kyc-column">
        <p
          className={`kyc-display ${
            client.kycState === 1
              ? 'kyc-open'
              : client.kycState === 2
              ? 'kyc-sent'
              : client.kycState >= 3
              ? 'kyc-signed'
              : ''
          }`}
        >
          {client.kycState === 0
            ? 'Ej skapad'
            : client.kycState === 1
            ? 'Öppen'
            : client.kycState === 2
            ? 'Inskickad'
            : client.kycState >= 3
            ? 'Signerad'
            : ''}
        </p>
      </td>
      <td className="connection-column">
        <FontAwesomeIcon
          className={`connection-icon
            ${client.externalConnectionActive ? 'ok' : 'error'}
          `}
          icon={client.externalConnectionActive ? faLink : faLinkSlash}
        />
      </td>
      <td className="connection-column">
        <FontAwesomeIcon
          className={`connection-icon
            ${
              client.bankConnectionActive
                ? 'ok'
                : client.hadBankConnection
                ? 'warning'
                : 'error'
            }
          `}
          icon={client.bankConnectionActive ? faLink : faLinkSlash}
        />
      </td>
      <td className="connection-column">
        <FontAwesomeIcon
          className={`connection-icon
            ${client.skvConnected ? 'ok' : 'error'}
          `}
          icon={client.skvConnected ? faLink : faLinkSlash}
        />
      </td>
    </tr>
  );
};
