import './Dashboard.scss';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Moment from 'moment';
import UserService from '../../../Services/UserService';
import ConnectAccountingModal from '../Modals/ConnectAccountingModal';
import CompanyTable from './CompanyTable/CompanyTable';
import LoadingWindow from '../../../Components/LoadingBar/LoadingBar';
import LabeledInfo from '../../../Components/LabeledInfo/LabeledInfo';
import XpektorButton from '../../../Components/XpektorButton/XpektorButton';
import CreateAuthLinkModal from '../Modals/CreateAuthLinkModal';
import Dropdown from '../../../Components/Dropdown/Dropdown';

/* eslint-disable no-alert */
export default function Dashboard() {
  const [clients, setClients] = useState([]);
  const [authState, setAuthState] = useState({});
  const history = useHistory();
  const { search } = window.location;
  const query = new URLSearchParams(search).get('s');
  const [showAuthenticateClient, setShowAuthenticateClient] = useState(false);
  const [showCreateAuthLinkModal, setShowCreateAuthLinkModal] = useState(false);

  const [isLoading, setLoadingState] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [lastUpdated, setLastUpdated] = useState('N/A');
  const [clientDropdownText, setClientDropdownText] =
    useState('Kopplade klienter');
  const [clientDropdownOpen, setClientDropdownOpen] = useState(false);
  const [connectionFilter, setConnectionFilter] = useState('');

  const fetchDashboard = async () => {
    setLoadingState(true);
    setLoadingText('Hämtar klienter...');

    await UserService.getDashbaord()
      .then((response) => {
        setClients(response.data);
        setLastUpdated(Moment.utc().local().format('YYYY-MM-DD HH:mm'));
      })
      .catch(() => {
        alert('Något gick fel försök igen om en stund');
      })
      .finally(() => {
        setLoadingState(false);
      });
  };

  useEffect(() => {
    const getDashboard = async () => {
      setLoadingState(true);
      setLoadingText('Hämtar klienter...');

      await UserService.getDashbaord()
        .then((response) => {
          setClients(response.data);
          setLastUpdated(Moment.utc().local().format('YYYY-MM-DD HH:mm'));
        })
        .catch(() => {
          alert('Något gick fel försök igen om en stund');
        })
        .finally(() => {
          setLoadingState(false);
        });

      const user = UserService.getCurrentUser();
      setAuthState((state) => ({ ...state, user }));

      const searchQuery = new URLSearchParams(window.location).get('s');
      if (searchQuery === null) {
        if (user?.role === 2) {
          setConnectionFilter('');
          setClientDropdownText('Alla klienter');
        } else {
          setConnectionFilter('connected');
        }
      }
    };

    document.title = 'Dashboard';
    getDashboard();
  }, []);

  function closeClientDropdownIfOpen() {
    if (clientDropdownOpen) {
      setClientDropdownOpen(false);
    }
  }

  const [filteredClients, setFilteredClients] = useState([]);
  useEffect(() => {
    const connectionFiltered =
      connectionFilter !== ''
        ? clients.filter((client) => {
            return connectionFilter === 'connected'
              ? client.unconnected === false
              : connectionFilter === 'unconnected'
              ? client.unconnected === true
              : false;
          })
        : clients;
    const queryFiltered = query
      ? connectionFiltered.filter((client) => {
          return (
            client.name?.toLowerCase().includes(query?.toLowerCase()) ||
            client.organizationNumber.toString().includes(query?.toLowerCase())
          );
        })
      : connectionFiltered;
    setFilteredClients(queryFiltered);
  }, [query, connectionFilter, clients]);

  const redirectToCompanyInfo = (clientId) => {
    history.push(`/app/companyinfo/${clientId}/score`);
  };

  const [sortOrder, setSortOrder] = useState('asc');

  const sortStringCompare = (a, b) => {
    const stringA = a?.toUpperCase();
    const stringB = b?.toUpperCase();

    if (stringA < stringB) {
      return -1;
    }
    if (stringA > stringB) {
      return 1;
    }
    return 0;
  };

  const sortByName = () => {
    if (sortOrder === 'asc') {
      filteredClients.sort((a, b) => {
        return sortStringCompare(a.name, b.name);
      });
      setSortOrder('desc');
    } else if (sortOrder === 'desc') {
      filteredClients.sort((a, b) => {
        return sortStringCompare(a.name, b.name) * -1;
      });
      setSortOrder('asc');
    }
  };

  const sortByScore = () => {
    if (sortOrder === 'asc') {
      filteredClients.sort((a, b) => {
        const aScore = a.scoreGrade;
        const bScore = b.scoreGrade;

        const aValue =
          !a.externalConnectionActive && !a.bankConnectionActive
            ? `G${aScore ?? 'F'}`
            : aScore ?? 'F';
        const bValue =
          !b.externalConnectionActive && !b.bankConnectionActive
            ? `G${bScore ?? 'F'}`
            : bScore ?? 'F';

        return sortStringCompare(aValue, bValue);
      });
      setSortOrder('desc');
    } else if (sortOrder === 'desc') {
      filteredClients.sort((a, b) => {
        const aScore = a.scoreGrade;
        const bScore = b.scoreGrade;

        const aValue =
          !a.externalConnectionActive && !a.bankConnectionActive
            ? `G${aScore ?? 'F'}`
            : aScore ?? 'F';
        const bValue =
          !b.externalConnectionActive && !b.bankConnectionActive
            ? `G${bScore ?? 'F'}`
            : bScore ?? 'F';

        return sortStringCompare(aValue, bValue) * -1;
      });
      setSortOrder('asc');
    }
  };

  const sortByKyc = () => {
    if (sortOrder === 'asc') {
      filteredClients.sort((a, b) => {
        return b.kycState - a.kycState;
      });
      setSortOrder('desc');
    } else if (sortOrder === 'desc') {
      filteredClients.sort((a, b) => {
        return a.kycState - b.kycState;
      });
      setSortOrder('asc');
    }
  };

  const sortByAccountingConnection = () => {
    filteredClients.sort((a, b) => {
      const aConnection = a.externalConnectionActive ? 1 : 0;
      const bConnection = b.externalConnectionActive ? 1 : 0;
      let result = 0;
      if (sortOrder === 'asc') {
        result = bConnection - aConnection;
        setSortOrder('desc');
      } else if (sortOrder === 'desc') {
        result = aConnection - bConnection;
        setSortOrder('asc');
      }
      return result;
    });
  };

  const sortByBankConnection = () => {
    filteredClients.sort((a, b) => {
      const aConnection = a.bankConnectionActive
        ? 2
        : a.hadBankConnection
        ? 1
        : 0;
      const bConnection = b.bankConnectionActive
        ? 2
        : b.hadBankConnection
        ? 1
        : 0;
      let result = 0;
      if (sortOrder === 'asc') {
        result = bConnection - aConnection;
        setSortOrder('desc');
      } else if (sortOrder === 'desc') {
        result = aConnection - bConnection;
        setSortOrder('asc');
      }
      return result;
    });
  };

  const sortBySkvConnection = () => {
    filteredClients.sort((a, b) => {
      const aConnection = a.skvConnected ? 1 : 0;
      const bConnection = b.skvConnected ? 1 : 0;
      let result = 0;
      if (sortOrder === 'asc') {
        result = bConnection - aConnection;
        setSortOrder('desc');
      } else if (sortOrder === 'desc') {
        result = aConnection - bConnection;
        setSortOrder('asc');
      }
      return result;
    });
  };

  return (
    <div className="dashboard-wrapper">
      <div className="dashboard-nav-container">
        <LabeledInfo label="Senast hämtade:" info={lastUpdated} />
      </div>
      {authState.user?.role > 0 && (
        <div className="dashboard-nav-container">
          <Dropdown
            title={clientDropdownText}
            open={clientDropdownOpen}
            onClick={() => {
              setClientDropdownOpen(!clientDropdownOpen);
            }}
            onClickOutside={() => setClientDropdownOpen(false)}
            width={200}
          >
            <Dropdown.Item
              content="Kopplade klienter"
              onClick={() => {
                setClientDropdownText('Kopplade klienter');
                setConnectionFilter('connected');
                closeClientDropdownIfOpen();
              }}
            />
            <Dropdown.Item
              content="Ej signerade"
              onClick={() => {
                setClientDropdownText('Ej signerade');
                setConnectionFilter('unconnected');
                closeClientDropdownIfOpen();
              }}
            />
            <Dropdown.Item
              content="Alla klienter"
              onClick={() => {
                setClientDropdownText('Alla klienter');
                setConnectionFilter('');
                closeClientDropdownIfOpen();
              }}
            />
          </Dropdown>
          <div className="dash-add-client">
            {authState.user?.role === 10 && (
              <XpektorButton
                content="Lägg till med bokföring (System Admin)"
                onClick={() => {
                  setShowAuthenticateClient(true);
                }}
              />
            )}
            {authState.user?.role > 0 && (
              <XpektorButton
                content="Lägg till klient"
                onClick={() => {
                  setShowCreateAuthLinkModal(true);
                }}
              />
            )}
          </div>
        </div>
      )}
      <LoadingWindow active={isLoading} status={loadingText} />
      <CompanyTable
        order={sortOrder}
        sortByName={sortByName}
        sortByScore={sortByScore}
        sortByKyc={sortByKyc}
        sortByAccounting={sortByAccountingConnection}
        sortByBank={sortByBankConnection}
        sortBySkv={sortBySkvConnection}
      >
        {isLoading === false &&
          filteredClients.map((data) => {
            return (
              <CompanyTable.Item
                client={data}
                onClick={() => redirectToCompanyInfo(data.id)}
                key={data.id}
              />
            );
          })}
      </CompanyTable>
      {showAuthenticateClient && (
        <ConnectAccountingModal
          handleClose={() => setShowAuthenticateClient(false)}
          authLevel={authState.user?.role}
        />
      )}
      {showCreateAuthLinkModal && (
        <CreateAuthLinkModal
          handleClose={() => setShowCreateAuthLinkModal(false)}
          onClientCreated={fetchDashboard}
        />
      )}
    </div>
  );
}
