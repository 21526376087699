import './CustomerClientsModal.scss';
import './SystemAdminModalTable.scss';
import './SystemAdminModalComponents.scss';
import { useEffect, useState } from 'react';
import Moment from 'moment';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortUp,
  faSortDown,
  faLink,
  faLinkSlash,
} from '@fortawesome/free-solid-svg-icons';
import Searchbar from '../../../../Components/Searchbar/Searchbar';
import DataPanel from '../../../../Components/DataPanel/DataPanel';
import { monthTranslations } from '../../../../Components/Utilities/HelperFunctions';

/* eslint-disable react/prop-types */
function ClientItem({ client }) {
  return (
    <div className="table-item-row client-table-row">
      <p>{client.name}</p>
      <p>{client.organizationNumber}</p>
      <p>
        {client.dateAdded
          ? Moment(client.dateAdded).utc().format('YYYY-MM-DD HH:mm')
          : 'N/A'}
      </p>
      <div />
      {client.externalConnectionActive ? (
        client.accountingSystem === 1 ? (
          <p className="connection-text connected">Fortnox</p>
        ) : client.accountingSystem === 2 ? (
          <p className="connection-text connected">Visma</p>
        ) : client.accountingSystem === 3 ? (
          <p className="connection-text connected">
            Bjorn
            <br />
            Lunden
          </p>
        ) : (
          <p>N/A</p>
        )
      ) : (
        <FontAwesomeIcon className="connection-icon error" icon={faLinkSlash} />
      )}
      <FontAwesomeIcon
        className={`connection-icon ${
          client.bankConnectionActive
            ? 'connected'
            : client.hadBankConnection
            ? 'broken'
            : 'error'
        }`}
        icon={client.bankConnectionActive ? faLink : faLinkSlash}
      />
      <FontAwesomeIcon
        className={`connection-icon ${
          client.skvConnected ? 'connected' : 'error'
        }`}
        icon={client.skvConnected ? faLink : faLinkSlash}
      />
    </div>
  );
}

function TableHeaderItem({ text, onClick, orderIcon }) {
  return (
    <button
      type="button"
      className={`table-head-button ${!onClick ? 'not-sortable' : ''}`}
      onClick={() => {
        if (onClick) onClick();
      }}
      disabled={!onClick}
    >
      <p> {text}</p>
      {onClick ? (
        <FontAwesomeIcon className="sort-icon" icon={orderIcon ?? faSort} />
      ) : (
        ''
      )}
    </button>
  );
}

/* eslint-disable jsx-a11y/no-autofocus */
export default function CustomerClientsModal({
  clients,
  handleClose,
  customerName,
  statistics,
}) {
  const [clientCount, setClientCount] = useState(0);
  const [somethingConnectedCount, setSomethingConnectedCount] = useState(0);
  const [accountingConnectedCount, setAccountingConnectedCount] = useState(0);
  const [bankConnectedCount, setBankConnectedCount] = useState(0);
  const [skvConnectedCount, setSkvConnectedCount] = useState(0);
  const [newClientsThisMonth, setNewClientsThisMonth] = useState(0);
  const [newClientsPreviousMonth, setNewClientsPreviousMonth] = useState(0);
  const [oldClientsThisMonth, setOldClientsThisMonth] = useState(0);
  const [oldClientsPreviousMonth, setOldClientsPreviousMonth] = useState(0);

  const [graphData, setGraphData] = useState([]);

  const [arrowIcon, setArrowIcon] = useState();
  const [currentOrdered, setCurrentOrdered] = useState('');
  const [order, setOrder] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');

  const sortStringCompare = (a, b) => {
    const stringA = a?.toUpperCase();
    const stringB = b?.toUpperCase();

    if (stringA < stringB) {
      return -1;
    }
    if (stringA > stringB) {
      return 1;
    }
    return 0;
  };

  function SortByName() {
    if (!clients) return;

    if (order === 'asc') {
      clients.sort((a, b) => {
        return sortStringCompare(a.name, b.name);
      });
      setOrder('desc');
    } else if (order === 'desc') {
      clients.sort((a, b) => {
        return sortStringCompare(a.name, b.name) * -1;
      });
      setOrder('asc');
    }
  }

  function SortByDate() {
    if (order === 'asc') {
      clients.sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded));
      setOrder('desc');
    } else if (order === 'desc') {
      clients.sort((a, b) => new Date(a.dateAdded) - new Date(b.dateAdded));
      setOrder('asc');
    }
  }

  function SortByAccountingConnection() {
    clients.sort((a, b) => {
      const aConnection = a.externalConnectionActive ? 1 : 0;
      const bConnection = b.externalConnectionActive ? 1 : 0;
      let result = 0;
      if (order === 'asc') {
        result = bConnection - aConnection;
        setOrder('desc');
      } else if (order === 'desc') {
        result = aConnection - bConnection;
        setOrder('asc');
      }
      return result;
    });
  }

  function SortByBankConnection() {
    clients.sort((a, b) => {
      const aConnection = a.bankConnectionActive
        ? 2
        : a.hadBankConnection
        ? 1
        : 0;
      const bConnection = b.bankConnectionActive
        ? 2
        : b.hadBankConnection
        ? 1
        : 0;
      let result = 0;
      if (order === 'asc') {
        result = bConnection - aConnection;
        setOrder('desc');
      } else if (order === 'desc') {
        result = aConnection - bConnection;
        setOrder('asc');
      }
      return result;
    });
  }

  function SortBySkvConnection() {
    clients.sort((a, b) => {
      const aConnection = a.skvConnected ? 1 : 0;
      const bConnection = b.skvConnected ? 1 : 0;
      let result = 0;
      if (order === 'asc') {
        result = bConnection - aConnection;
        setOrder('desc');
      } else if (order === 'desc') {
        result = aConnection - bConnection;
        setOrder('asc');
      }
      return result;
    });
  }

  function OnSort(sortId) {
    setCurrentOrdered(sortId);
    setArrowIcon(order === 'asc' ? faSortUp : faSortDown);
  }

  const [filteredClients, setFilteredClients] = useState([]);

  useEffect(() => {
    const newFilteredClients =
      searchTerm.length > 0
        ? clients.filter((client) => {
            return (
              client.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
              client.organizationNumber
                .toString()
                .includes(searchTerm?.toLowerCase())
            );
          })
        : clients;
    setFilteredClients(newFilteredClients);
  }, [searchTerm, clients]);

  useEffect(() => {
    const setData = async () => {
      if (statistics?.clientGraphData) {
        setGraphData([
          { id: 'Klienter', data: statistics.clientGraphData.data },
        ]);
      }

      setClientCount(statistics.clientCount);
      setSomethingConnectedCount(statistics.anyConnectionCount);
      setAccountingConnectedCount(statistics.accountingConnectionCount);
      setBankConnectedCount(statistics.bankConnectionCount);
      setSkvConnectedCount(statistics.skvConnectionCount);

      setNewClientsThisMonth(statistics.clientsAddedThisMonth);
      setNewClientsPreviousMonth(statistics.clientsAddedLastMonth);
      setOldClientsThisMonth(statistics.previousClientsThisMonth);
      setOldClientsPreviousMonth(statistics.previousClientsLastMonth);
    };
    if (!statistics || Object.keys(statistics).length === 0) return;

    setData();
  }, [statistics]);

  return (
    <Modal
      show
      onHide={handleClose}
      backdrop="static"
      centered
      keyboard={false}
      className="border-0 xpektor-bootstrap-modal customer-clients-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p>
            Klienter under <b>{customerName}</b>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="sysadmin-panel-grid">
          <div className="sysadmin-panel-column">
            <DataPanel
              title={`Denna månad (${
                monthTranslations[Moment.utc().local().month()]
              })`}
              titleSize="s"
            >
              <DataPanel.ValueEntry
                label="Nya klienter"
                value={newClientsThisMonth}
                size="s"
              />
              <DataPanel.ValueEntry
                label="Tidigare klienter"
                value={oldClientsThisMonth}
                size="s"
              />
            </DataPanel>
            <DataPanel
              title={`Förra månaden (${
                monthTranslations[
                  Moment.utc().local().subtract(1, 'months').month()
                ]
              })`}
              titleSize="s"
            >
              <DataPanel.ValueEntry
                label="Nya klienter"
                value={newClientsPreviousMonth}
                size="s"
              />
              <DataPanel.ValueEntry
                label="Tidigare klienter"
                value={oldClientsPreviousMonth}
                size="s"
              />
            </DataPanel>
          </div>
          <DataPanel title="Över tid" titleSize="s">
            <DataPanel.LineChart graphData={graphData} />
          </DataPanel>
          <div className="sysadmin-panel-column">
            <DataPanel title="Kopplade klienter" titleSize="s">
              <DataPanel.BarEntry
                text="Kopplade klienter"
                count={somethingConnectedCount}
                total={clientCount}
              />
            </DataPanel>
            <DataPanel title="Varav" titleSize="s">
              <DataPanel.BarEntry
                text="Bokföring kopplad"
                count={accountingConnectedCount}
                total={somethingConnectedCount}
              />
              <DataPanel.BarEntry
                text="Bank kopplad"
                count={bankConnectedCount}
                total={somethingConnectedCount}
              />
              <DataPanel.BarEntry
                text="Skatteverket kopplat"
                count={skvConnectedCount}
                total={somethingConnectedCount}
              />
            </DataPanel>
          </div>
        </div>
        <Searchbar
          placeholder="Sök klient"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="sysadmin-table sysadmin-client-table">
          <div className="client-table-row sysadmin-table-head">
            <TableHeaderItem
              text="Namn"
              onClick={() => {
                SortByName();
                OnSort('name');
              }}
              orderIcon={currentOrdered === 'name' ? arrowIcon : null}
            />
            <TableHeaderItem text="Org." />
            <TableHeaderItem
              text="Tillagd"
              onClick={() => {
                SortByDate();
                OnSort('date');
              }}
              orderIcon={currentOrdered === 'date' ? arrowIcon : null}
            />
            <div />
            <TableHeaderItem
              text="Bokföring"
              onClick={() => {
                SortByAccountingConnection();
                OnSort('accounting');
              }}
              orderIcon={currentOrdered === 'accounting' ? arrowIcon : null}
            />
            <TableHeaderItem
              text="Bank"
              onClick={() => {
                SortByBankConnection();
                OnSort('bank');
              }}
              orderIcon={currentOrdered === 'bank' ? arrowIcon : null}
            />
            <TableHeaderItem
              text="SKV"
              onClick={() => {
                SortBySkvConnection();
                OnSort('skv');
              }}
              orderIcon={currentOrdered === 'skv' ? arrowIcon : null}
            />
          </div>
          <div className="table-content">
            {clients && clients.length > 0 ? (
              filteredClients && filteredClients.length > 0 ? (
                filteredClients.map((client) => {
                  return <ClientItem client={client} key={client.id} />;
                })
              ) : (
                <p className="no-items-item">Inga sökresultat</p>
              )
            ) : (
              <p className="no-items-item">Finns inga klienter</p>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
