import './CreateCustomerModal.scss';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import isEmail from 'validator/lib/isEmail';
import CustomerService from '../../../../Services/CustomerService';
import {
  ClassicForm,
  FormInput,
  FormButton,
  FormDropdown,
} from '../../../../Components/XpektorForms/XpektorForms';
import Regex from '../../../../Components/Utilities/Regex';

/* eslint-disable react/prop-types */
export default function CreateCustomerModal({
  mainCustomers,
  handleClose,
  onCustomerCreated,
}) {
  const [name, setName] = useState('');
  const [orgNumber, setOrgNumber] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [billingEmail, setBillingEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [customerType, setCustomerType] = useState(1);
  const [mainCustomerId, setMainCustomerId] = useState(null);

  const [nameError, setNameError] = useState('');
  const [orgNumberError, setOrgNumberError] = useState('');
  const [billingEmailError, setBillingEmailError] = useState('');

  const requiredErrorText = 'Fältet kan inte vara tomt';
  const orgNumberErrorText = 'Måste innehålla 10 siffror';
  const emailErrorText = 'Ej giltigt email';

  const [creating, setCreating] = useState(false);

  const validateForm = () => {
    let errors = 0;
    setNameError('');
    setOrgNumberError('');
    setBillingEmailError('');

    if (name.length === 0) {
      setNameError(requiredErrorText);
      errors += 1;
    }

    if (orgNumber.length === 0) {
      setOrgNumberError(requiredErrorText);
      errors += 1;
    } else if (!Regex.testOrgNr(orgNumber)) {
      setOrgNumberError(orgNumberErrorText);
      errors += 1;
    }

    if (billingEmail.length > 0 && !isEmail(billingEmail)) {
      setBillingEmailError(emailErrorText);
    }

    if (errors === 0) return true;

    return false;
  };

  const createCustomer = async (event) => {
    event.preventDefault();

    if (!validateForm()) return;

    setCreating(true);
    await CustomerService.createCustomer(
      name,
      orgNumber,
      phoneNumber,
      contactPerson,
      billingEmail,
      customerType,
      mainCustomerId
    )
      .then((response) => {
        onCustomerCreated(response.data);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setOrgNumberError('Kunden finns redan');
        }
        setCreating(false);
      });
  };

  return (
    <Modal
      show
      size="lg"
      onHide={handleClose}
      backdrop="static"
      centered
      keyboard={false}
      className="border-0 xpektor-bootstrap-modal create-customer-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Skapa kund</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ClassicForm onSubmit={createCustomer}>
          <div className="form-row">
            <div className="form-column">
              <FormInput
                label="Namn *"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);

                  if (e.target.value.length === 0) {
                    setNameError(requiredErrorText);
                  } else {
                    setNameError('');
                  }
                }}
                infoText={nameError}
                maxLength={100}
              />
              <FormInput
                label="Org. nummer *"
                value={orgNumber}
                onChange={(e) => {
                  setOrgNumber(e.target.value);

                  if (e.target.value.length === 0) {
                    setOrgNumberError(requiredErrorText);
                  } else if (!Regex.testOrgNr(e.target.value)) {
                    setOrgNumberError(orgNumberErrorText);
                  } else {
                    setOrgNumberError('');
                  }
                }}
                infoText={orgNumberError}
                maxLength={11}
              />
              <FormDropdown
                defaultValue={1}
                label="Kundtyp"
                onChange={(e) => setCustomerType(Number(e.target.value))}
                styleClass={
                  customerType === 1
                    ? 'advice'
                    : customerType === 2
                    ? 'finance'
                    : customerType === 3
                    ? 'subsegment'
                    : ''
                }
              >
                <option value={1}>Advice</option>
                <option value={2}>Finance</option>
                <option value={3}>Subsegment</option>
              </FormDropdown>
              <FormDropdown
                label="Huvudkund"
                defaultValue={null}
                onChange={(e) => setMainCustomerId(e.target.value)}
              >
                <option value={null}>Ingen</option>
                {mainCustomers.map((c) => {
                  return (
                    <option key={c.id} value={c.id}>
                      {c.name}
                    </option>
                  );
                })}
              </FormDropdown>
            </div>
            <div className="form-column">
              <FormInput
                label="Kontaktperson"
                value={contactPerson}
                onChange={(e) => {
                  setContactPerson(e.target.value);
                }}
                maxLength={100}
              />
              <FormInput
                label="Faktureringsmail"
                value={billingEmail}
                onChange={(e) => {
                  setBillingEmail(e.target.value);
                  if (e.target.value.length > 0 && !isEmail(e.target.value)) {
                    setBillingEmailError(emailErrorText);
                  } else {
                    setBillingEmailError('');
                  }
                }}
                infoText={billingEmailError}
                maxLength={100}
              />
              <FormInput
                label="Telefonnummer"
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
                maxLength={20}
              />
            </div>
          </div>
          <FormButton
            text={creating ? 'Skapar...' : 'Skapa'}
            disabled={creating}
          />
        </ClassicForm>
      </Modal.Body>
    </Modal>
  );
}
