/* eslint-disable react/prop-types */
import './AdminSettings.scss';
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import TabNav from '../../../Components/TabNav/TabNav';
import GeneralSettings from './GeneralSettings';
import ClientSettings from './ClientSettings/ClientSettings';
import UserSettings from './UserSettings/UserSettings';
import AuthLinkSettings from './AuthLinkSettings/AuthLinkSettings';
import XpektorSlider from '../../../Components/XpektorSlider/XpektorSlider';
import UserService from '../../../Services/UserService';

export default function AdminSettings() {
  const [activeTab, setActiveTab] = useState('');
  const location = useLocation();
  const history = useHistory();
  const isLocation = (path) => {
    return window.location.href.indexOf(path) !== -1;
  };

  useEffect(() => {
    const getData = async () => {
      const user = await UserService.getCurrentUser();
      if (user.role < 1) {
        history.push(`/app/dashboard`);
      }
    };

    document.title = 'Admin';
    getData();
  }, []);

  useEffect(() => {
    if (isLocation('settings/clients')) {
      setActiveTab('clients');
    } else if (isLocation('settings/users')) {
      setActiveTab('users');
    } else if (isLocation('settings/links')) {
      setActiveTab('links');
    } else {
      setActiveTab('general');
    }
  }, [location]);

  function renderSettings() {
    return (
      <XpektorSlider
        activeSlideIndex={
          activeTab === 'general'
            ? 0
            : activeTab === 'clients'
            ? 1
            : activeTab === 'users'
            ? 2
            : activeTab === 'links'
            ? 3
            : ''
        }
      >
        <XpektorSlider.Slide hidden={activeTab !== 'general'}>
          <GeneralSettings />
        </XpektorSlider.Slide>
        <XpektorSlider.Slide hidden={activeTab !== 'clients'}>
          <ClientSettings />
        </XpektorSlider.Slide>
        <XpektorSlider.Slide hidden={activeTab !== 'users'}>
          <UserSettings />
        </XpektorSlider.Slide>
        <XpektorSlider.Slide hidden={activeTab !== 'links'}>
          <AuthLinkSettings />
        </XpektorSlider.Slide>
      </XpektorSlider>
    );
  }

  return (
    <div className="admin-settings-wrapper">
      <div className="admin-settings-head">
        <p className="admin-settings-title">Admin</p>
      </div>
      <div className="admin-nav-container">
        <TabNav>
          <TabNav.Tab
            title="Allmänt"
            active={activeTab === 'general'}
            onClick={() =>
              history.replace({ pathname: '/app/settings/general' })
            }
          />
          <TabNav.Tab
            title="Klienter"
            active={activeTab === 'clients'}
            onClick={() =>
              history.replace({ pathname: '/app/settings/clients' })
            }
          />
          <TabNav.Tab
            title="Användare"
            active={activeTab === 'users'}
            onClick={() => history.replace({ pathname: '/app/settings/users' })}
          />
          <TabNav.Tab
            title="Länkar"
            active={activeTab === 'links'}
            onClick={() => history.replace({ pathname: '/app/settings/links' })}
          />
        </TabNav>
      </div>
      {renderSettings()}
    </div>
  );
}
