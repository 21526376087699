import './CustomerHistoryModal.scss';
import './SystemAdminModalComponents.scss';
import { useState, useEffect } from 'react';
import Moment from 'moment';
import { Modal } from 'react-bootstrap';
import DataPanel from '../../../../Components/DataPanel/DataPanel';
import { monthTranslations } from '../../../../Components/Utilities/HelperFunctions';

/* eslint-disable react/prop-types */

function ActionItem({ action }) {
  return (
    <div className="table-item-row history-table-row">
      <p>
        {action.actionType === 1
          ? 'Länk'
          : action.actionType === 2
          ? 'KYC'
          : 'N/A'}
      </p>

      <p>{action.companyName ?? 'N/A'}</p>
      <p>{action.orgNr}</p>
      <p>{action.userEmail}</p>
      <p>
        {action.created
          ? Moment(action.created).format('YYYY-MM-DD HH:mm')
          : '2025-03-05 10:12'}
      </p>
    </div>
  );
}

function TableHeaderItem({ text }) {
  return (
    <button type="button" className="table-head-button" disabled>
      <p>{text}</p>
    </button>
  );
}

export default function CustomerHistoryModal({
  handleClose,
  customerName,
  actions,
}) {
  const [linksThisMonth, setLinksThisMonth] = useState(0);
  const [kycThisMonth, setKycThisMonth] = useState(0);
  const [linksPreviousMonth, setLinksPreviousMonth] = useState(0);
  const [kycPreviousMonth, setKycPreviousMonth] = useState(0);
  const [graphData, setGraphData] = useState([]);

  const [actionMonths, setActionMonths] = useState([]);

  useEffect(() => {
    if (actions.length === 0) return;

    const currentMonth = Moment().utc().local().format('YYYY-MM');
    const previousMonth = Moment.utc()
      .local()
      .subtract(1, 'months')
      .format('YYYY-MM');

    let uniqueMonths = [];
    uniqueMonths.push(currentMonth);

    for (let i = 0; i < actions.length; i += 1) {
      const month = Moment(actions[i].created).format('YYYY-MM');
      if (uniqueMonths.indexOf(month) === -1) {
        uniqueMonths.push(month);
      }
    }
    uniqueMonths = uniqueMonths.sort((a, b) => new Date(a) - new Date(b));

    const sixMonthsAgo = Moment.utc()
      .local()
      .subtract(6, 'months')
      .format('YYYY-MM');

    const linkGraphData = [];
    const kycGraphData = [];

    for (let i = 0; i < uniqueMonths.length; i += 1) {
      const linkCount = actions.filter(
        (action) =>
          action.actionType === 1 &&
          Moment(action.created).format('YYYY-MM') === uniqueMonths[i]
      ).length;

      const kycCount = actions.filter(
        (action) =>
          action.actionType === 2 &&
          Moment(action.created).format('YYYY-MM') === uniqueMonths[i]
      ).length;

      if (
        uniqueMonths.length <= 6 ||
        new Date(uniqueMonths[i]) >= new Date(sixMonthsAgo)
      ) {
        linkGraphData.push({ x: uniqueMonths[i], y: linkCount });
        kycGraphData.push({ x: uniqueMonths[i], y: kycCount });
      }

      if (uniqueMonths[i] === currentMonth) {
        setLinksThisMonth(linkCount);
        setKycThisMonth(kycCount);
      } else if (uniqueMonths[i] === previousMonth) {
        setLinksPreviousMonth(linkCount);
        setKycPreviousMonth(kycCount);
      }
    }

    setGraphData([
      { id: 'Länkar', data: linkGraphData },
      { id: 'KYC', data: kycGraphData },
    ]);
    setActionMonths(uniqueMonths.reverse());
  }, [actions]);

  return (
    <Modal
      show
      onHide={handleClose}
      backdrop="static"
      centered
      keyboard={false}
      className="border-0 xpektor-bootstrap-modal customer-history-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p>
            Aktivitetshistorik för <b>{customerName}</b>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="sysadmin-panel-grid">
          <div className="sysadmin-panel-column">
            <DataPanel
              title={`Denna månad (${
                monthTranslations[Moment.utc().local().month()]
              })`}
              titleSize="s"
            >
              <DataPanel.ValueEntry
                label="Länkar skapade"
                value={linksThisMonth}
                size="s"
              />
              <DataPanel.ValueEntry
                label="KYC skapade"
                value={kycThisMonth}
                size="s"
              />
            </DataPanel>
            <DataPanel
              title={`Förra månaden (${
                monthTranslations[
                  Moment.utc().local().subtract(1, 'months').month()
                ]
              })`}
              titleSize="s"
            >
              <DataPanel.ValueEntry
                label="Länkar skapade"
                value={linksPreviousMonth}
                size="s"
              />
              <DataPanel.ValueEntry
                label="KYC skapade"
                value={kycPreviousMonth}
                size="s"
              />
            </DataPanel>
          </div>
          <DataPanel title="Över tid" titleSize="s">
            <DataPanel.LineChart graphData={graphData} />
          </DataPanel>
        </div>
        <div className="sysadmin-table sysadmin-history-table">
          <div className="history-table-row sysadmin-table-head">
            <TableHeaderItem text="Typ" />
            <TableHeaderItem text="Klient" />
            <TableHeaderItem text="Org." />
            <TableHeaderItem text="Skapare" />
            <TableHeaderItem text="Datum" />
          </div>
          <div className="table-content">
            {actions && actions.length > 0 ? (
              actionMonths.map((month) => {
                return (
                  <div className="month-actions" key={month}>
                    <p className="month-title">{month}</p>
                    {actions
                      .sort((a, b) => new Date(b.created) - new Date(a.created))
                      .map((action) => {
                        return (
                          Moment(action.created).format('YYYY-MM') ===
                            month && (
                            <ActionItem action={action} key={action.id} />
                          )
                        );
                      })}
                  </div>
                );
              })
            ) : (
              <p className="no-items-item">Finns ingen historik</p>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
