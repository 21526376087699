/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import './DataPanel.scss';
import Moment from 'moment';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAnglesDown,
  faAnglesUp,
  faChevronRight,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { Tooltip } from 'react-tooltip';
import { ResponsiveLine } from '@nivo/line';
import HoverableText from '../HoverableText/HoverableText';

export default function DataPanel({
  children,
  title,
  icon,
  iconText,
  scroll,
  givenRef,
  givenId,
  maxHeight,
  titleSize,
  centerContent,
}) {
  return (
    <div
      className="datapanel-wrapper"
      ref={givenRef}
      id={givenId}
      style={maxHeight ? { maxHeight } : {}}
    >
      {title && (
        <div
          className={`datapanel-title-container ${
            titleSize === 'm'
              ? 'medium-title-container'
              : titleSize === 's'
              ? 'small-title-container'
              : 'large-title-container'
          }`}
        >
          <p>{title}</p>
          {icon && (
            <FontAwesomeIcon icon={icon} className="datapanel-title-icon" />
          )}
          {iconText && (
            <div
              className={`datapanel-title-icon-background grade-${iconText}`}
            >
              <p className="datapanel-title-icon-text">{iconText}</p>
            </div>
          )}
        </div>
      )}
      <div
        className={`datapanel-content-container ${
          scroll ? 'scrollable-panel-content' : ''
        } ${centerContent ? 'datapanel-centered-content' : ''}`}
      >
        {children}
      </div>
    </div>
  );
}

DataPanel.Subtitle = function Subtitle({ title, info, green, red }) {
  return (
    <HoverableText
      styleClass={`sub-title ${
        green ? 'datapanel-green' : red ? 'datapanel-red' : ''
      }`}
      text={title}
      info={info}
    />
  );
};

/** Used for displaying a labeled value in a module. */
DataPanel.ValueEntry = function ValueEntry({
  label,
  info,
  value,
  valueType,
  size,
}) {
  let formattedValue = '';
  let formattedSign = '';

  if (valueType === 'currency') {
    formattedValue = Number(Number(value).toFixed(0))
      .toLocaleString()
      .replaceAll(',', ' ');
    formattedSign = 'kr';
  } else if (valueType === 'percentage') {
    formattedValue = Number(value)
      .toFixed(0)
      .toLocaleString()
      .replaceAll(',', ' ');
    formattedSign = '%';
  } else {
    formattedValue = value;
    formattedSign = valueType;
  }

  return (
    <div className="datapanel-entry-container">
      <div
        className={`entry-title-container ${
          size === 's' ? 'small-title-entry' : ''
        }`}
      >
        <HoverableText styleClass="entry-text" text={label} info={info} />
        <div className="entry-value">
          <p className="value">{formattedValue}</p>
          <p className="value-sign">{formattedSign}</p>
        </div>
      </div>
    </div>
  );
};

DataPanel.TriggerEntry = function TriggerEntry({
  name,
  info,
  icon,
  requirement,
  tooltipId,
  expandedContent,
}) {
  const [toolTipContent, setToolTipContent] = useState('');
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    if (expandedContent) {
      setExpanded(!expanded);
    }
  };

  return (
    <div className="datapanel-entry-container">
      <button
        className={`entry-title-container ${
          expandedContent ? 'entry-expandable' : ''
        }`}
        type="button"
        onClick={() => toggleExpanded()}
        onKeyDown={() => toggleExpanded()}
      >
        {icon && <FontAwesomeIcon icon={icon} className="entry-icon warning" />}
        <p className="entry-text">{name}</p>
        {info && (
          <FontAwesomeIcon
            icon={faCircleQuestion}
            className="info-icon"
            data-tooltip-id={tooltipId}
            onMouseEnter={() => {
              setToolTipContent(info);
            }}
          />
        )}
        <div className="trigger-entry-end-container">
          {requirement && (
            <div
              className="datapanel-entry-tag"
              data-tooltip-id={tooltipId}
              onMouseEnter={() => {
                setToolTipContent(requirement);
              }}
            >
              <p>KRAV</p>
            </div>
          )}
          {expandedContent && (
            <FontAwesomeIcon
              className="entry-expand-icon"
              icon={expanded ? faChevronDown : faChevronRight}
            />
          )}
        </div>
      </button>
      {expanded && (
        <div className="entry-expanded-content">
          {expandedContent ?? <p>Finns ingenting här.</p>}
        </div>
      )}
      <Tooltip className="hover-info-tooltip" id={tooltipId} place="top">
        {toolTipContent}
      </Tooltip>
    </div>
  );
};

DataPanel.Button = function Button({ onClick, disabled, loading, text }) {
  return (
    <button
      className={`datapanel-normal-button ${disabled ? 'disabled-button' : ''}`}
      onClick={onClick}
      disabled={disabled || loading}
      type="button"
    >
      <p>{text}</p>
    </button>
  );
};

DataPanel.ExpandButton = function ExpandButton({
  expanded,
  onClick,
  text,
  loading,
}) {
  return (
    <button
      className="datapanel-expand-button"
      onClick={onClick}
      disabled={loading}
      type="button"
    >
      {loading ? (
        <div className="expand-loading-icon" />
      ) : (
        <>
          <p>{text ?? 'Expandera'}</p>
          <FontAwesomeIcon
            icon={expanded ? faAnglesUp : faAnglesDown}
            className="expand-icon"
          />
        </>
      )}
    </button>
  );
};

DataPanel.DoubleValueEntry = function DoubleValueEntry({
  title,
  subTitle,
  valueOne,
  valueTwo,
  valueOneName,
  valueTwoName,
}) {
  return (
    <div className="double-value-entry-container">
      <div className="double-value-entry-title-column">
        <p>{title}</p>
        {subTitle && <p className="datapanel-gray-text">{subTitle}</p>}
      </div>
      <div className="double-value-entry-column">
        <div className="double-value-entry-row">
          <p className="datapanel-gray-text">{valueOneName}</p>
          <p>{Moment(valueOne).format('YYYY-MM-DD')}</p>
        </div>
        {valueTwo && (
          <div className="double-value-entry-row">
            <p className="datapanel-gray-text">{valueTwoName}</p>
            <p>{Moment(valueTwo).format('YYYY-MM-DD')}</p>
          </div>
        )}
      </div>
    </div>
  );
};

DataPanel.LineChart = function LineChart({ graphData }) {
  const [graphYTicks, setGraphYTicks] = useState(0);

  useEffect(() => {
    if (!graphData || graphData.length === 0) return;

    let mergedData = [graphData[0].data];
    if (graphData.length > 1) {
      for (let i = 1; i < graphData.length; i += 1) {
        mergedData = [...mergedData, ...graphData[i].data];
      }
    }

    const highestNumber = mergedData.reduce((prev, current) =>
      prev && prev.y > current.y ? prev : current
    ).y;

    setGraphYTicks(Math.min(highestNumber, 6));
  }, [graphData]);

  const lineChartProperties = {
    enableArea: true,
    enablePoints: false,
    enableGridX: false,
    enableGridY: false,
    isInteractive: true,
    useMesh: true,
    lineWidth: '2px',
    axisLeft: {
      tickPadding: 2,
      tickValues: graphYTicks,
    },
    axisBottom: {
      tickPadding: 2,
    },
    margin: { top: 30, right: 0, bottom: 0, left: 0 },
    yScale: { type: 'linear', stacked: false },
    colors: ['#2E5BFF', '#56FD10', '#FFDE59'],
    defs: [
      {
        id: 'previewGradient',
        type: 'linearGradient',
        colors: [
          { offset: 0, color: 'inherit' },
          { offset: 100, color: 'inherit', opacity: 0 },
        ],
      },
    ],
    fill: [{ match: '*', id: 'previewGradient' }],
    legends: [
      {
        anchor: 'bottom-left',
        direction: 'column',
        justify: false,
        translateX: 10,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 0,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 10,
        symbolShape: 'circle',
      },
    ],
  };

  return (
    <div className="datapanel-line-chart">
      {graphData && graphData.length > 0 ? (
        <ResponsiveLine {...lineChartProperties} data={graphData} />
      ) : (
        ''
      )}
    </div>
  );
};

DataPanel.BarEntry = function BarEntry({ text, count, total, height }) {
  const width = count ? (count / total) * 100 : 0;

  return (
    <div
      className="datapanel-bar-entry"
      style={height ? { height: `${height}px` } : {}}
    >
      <div className="bar-fill" style={{ width: `${width}%` }}>
        <p>
          {text}: {count}/{total} {`(${Math.trunc(width)}%)`}
        </p>
      </div>
    </div>
  );
};

DataPanel.Placeholder = function Placeholder({ content }) {
  return <div className="datapanel-placeholder-content">{content}</div>;
};
