/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */

import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import UserService from '../../../../Services/UserService';
import CustomerService from '../../../../Services/CustomerService';
import ClientService from '../../../../Services/ClientService';
import ClientTable from './ClientTable/ClientTable';
import DataPanel from '../../../../Components/DataPanel/DataPanel';
import XpektorButton from '../../../../Components/XpektorButton/XpektorButton';
import HandleUserClientAccess from './Modals/HandleUserClientAccess';
import EditNotificationModal from './Modals/EditNotificationModal';
import CreateClientPopUp from './Modals/CreateClientPopUp';
import AuthenticateClient from './AuthenticateClient';
import DeleteModal from './DeleteModal';
import CreateAuthLinkModal from '../../Modals/CreateAuthLinkModal';
// import ExpandablePanel from '../../../../Components/ExpandablePanel/ExpandablePanel';

export default function ClientSettings() {
  const [clients, setClients] = useState([]);
  const [statistics, setStatistics] = useState({});
  const [clientCount, setClientCount] = useState(0);
  const [somethingConnectedCount, setSomethingConnectedCount] = useState(0);
  // const [anyAccessCount, setAnyAccessCount] = useState(0);
  const [accountingConnectedCount, setAccountingConnectedCount] = useState(0);
  const [bankConnectedCount, setBankConnectedCount] = useState(0);
  const [skvConnectedCount, setSkvConnectedCount] = useState(0);
  const [graphData, setGraphData] = useState([]);

  const [showUserAccessModal, setShowUserAccessModal] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [showEditClientModal, setShowEditClientModal] = useState(false);
  const [showCreateClientModal, setShowCreateClientModal] = useState(false);

  const [showAuthClient, setShowAuthClient] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [currentUser, setCurrentUser] = useState();
  const [clientToEdit, setClientToEdit] = useState();
  const [customerId, setCustomerId] = useState();
  const [action, setAction] = useState();
  const { search } = useLocation();

  const [order, setOrder] = useState('asc');

  const sortByName = () => {
    if (order === 'asc') {
      clients.sort((a, b) => {
        const nameA = a.name?.toUpperCase();
        const nameB = b.name?.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setOrder('desc');
    } else if (order === 'desc') {
      clients.sort((a, b) => {
        const nameA = a.name?.toUpperCase();
        const nameB = b.name?.toUpperCase();

        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      setOrder('asc');
    }
  };

  const sortByDate = () => {
    if (order === 'asc') {
      clients.sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded));
      setOrder('desc');
    } else if (order === 'desc') {
      clients.sort((a, b) => new Date(a.dateAdded) - new Date(b.dateAdded));
      setOrder('asc');
    }
  };

  useEffect(() => {
    const getData = async () => {
      const user = await UserService.getCurrentUser();
      setCurrentUser(user);
      await CustomerService.getCustomerClients(user.customerId)
        .then((response) => {
          setClients(response.data);
          setCustomerId(user.customerId);
        })
        .catch(() => {});

      await CustomerService.getCustomerStatistics(user.customerId)
        .then((response) => {
          setStatistics(response.data);
          setClientCount(response.data.clientCount);
          setSomethingConnectedCount(response.data.anyConnectionCount);
          // setAnyAccessCount(response.data.anyAccessCount);
          setAccountingConnectedCount(response.data.accountingConnectionCount);
          setBankConnectedCount(response.data.bankConnectionCount);
          setSkvConnectedCount(response.data.skvConnectionCount);
          if (response.data.clientGraphData) {
            setGraphData([
              { id: 'Klienter', data: response.data.clientGraphData.data },
            ]);
          }
        })
        .catch(() => {});
    };
    const decodeAction = async () => {
      const queryAction = new URLSearchParams(search).get('action');
      const clientId = new URLSearchParams(search).get('clientId');
      if (!queryAction) return;

      let client = null;
      if (clientId) {
        client = await ClientService.getClient(clientId);
        setClientToEdit(client.data);
      }
      if (queryAction === 'changeClientAccess') {
        setShowUserAccessModal(true);
      } else if (queryAction === 'createClient') {
        setShowAuthClient(true);
      }
    };
    decodeAction();
    getData();
  }, [search]);

  return (
    <div className="settings-content">
      <div className="panel-grid">
        <DataPanel title="Klienter" titleSize="m" centerContent>
          <DataPanel.ValueEntry
            label="Totalt antal klienter"
            value={clientCount}
          />
          <DataPanel.ValueEntry
            label="Nya klienter denna månad"
            value={statistics?.clientsAddedThisMonth ?? 'N/A'}
          />
          <DataPanel.ValueEntry
            label="Tidigare klienter"
            value={statistics?.previousClientsThisMonth ?? 'N/A'}
          />
        </DataPanel>
        <DataPanel title="Klienter över tid" titleSize="m">
          <DataPanel.LineChart graphData={graphData} />
        </DataPanel>
        <div className="panel-column">
          <DataPanel title="Kopplade klienter" titleSize="s">
            <DataPanel.BarEntry
              text="Kopplade klienter"
              count={somethingConnectedCount}
              total={clientCount}
              height={32}
            />
          </DataPanel>
          <DataPanel title="Varav" titleSize="s">
            <DataPanel.BarEntry
              text="Bokföring"
              count={accountingConnectedCount}
              total={somethingConnectedCount}
              height={32}
            />
            <DataPanel.BarEntry
              text="Bank"
              count={bankConnectedCount}
              total={somethingConnectedCount}
              height={32}
            />
            <DataPanel.BarEntry
              text="Skatteverket"
              count={skvConnectedCount}
              total={somethingConnectedCount}
              height={32}
            />
          </DataPanel>
        </div>
      </div>
      {/* <ExpandablePanel title="Statistik efter användare">
        <div className="panel-grid">
          <DataPanel
            title="Klienter per skapare"
            titleSize="m"
            maxHeight={230}
            scroll
          >
            {statistics.userStatistics && statistics.userStatistics.length > 0
              ? [...statistics.userStatistics]
                  .sort((a, b) => b.clientsCreated - a.clientsCreated)
                  .map((userStats) => {
                    return (
                      <DataPanel.BarEntry
                        key={
                          userStats.userId ??
                          `unknown-user-stats-${userStats.userEmail}`
                        }
                        count={userStats.clientsCreated}
                        text={
                          userStats.userName ??
                          (userStats.userEmail !== 'Unknown'
                            ? userStats.userEmail
                            : 'Okänd användare')
                        }
                        total={clientCount}
                        height={36}
                      />
                    );
                  })
              : ''}
          </DataPanel>
          <DataPanel
            title="Klienter med någonting kopplat per skapare"
            titleSize="m"
            maxHeight={230}
            scroll
          >
            {statistics.userStatistics && statistics.userStatistics.length > 0
              ? [...statistics.userStatistics]
                  .sort((a, b) => b.anyConnectionCount - a.anyConnectionCount)
                  .map((userStats) => {
                    return (
                      <DataPanel.BarEntry
                        key={
                          userStats.userId ??
                          `unknown-user-stats-${userStats.userEmail}`
                        }
                        count={userStats.anyConnectionCount}
                        text={
                          userStats.userName ??
                          (userStats.userEmail !== 'Unknown'
                            ? userStats.userEmail
                            : 'Okänd användare')
                        }
                        total={userStats.clientsCreated}
                        height={36}
                      />
                    );
                  })
              : ''}
          </DataPanel>
          <DataPanel
            title="Klienter har/haft något kopplat per skapare"
            titleSize="m"
            maxHeight={230}
            scroll
          >
            {statistics.userStatistics && statistics.userStatistics.length > 0
              ? [...statistics.userStatistics]
                  .sort((a, b) => b.anyAccessCount - a.anyAccessCount)
                  .map((userStats) => {
                    return (
                      <DataPanel.BarEntry
                        key={
                          userStats.userId ??
                          `unknown-user-stats-${userStats.userEmail}`
                        }
                        count={userStats.anyAccessCount}
                        text={
                          userStats.userName ??
                          (userStats.userEmail !== 'Unknown'
                            ? userStats.userEmail
                            : 'Okänd användare')
                        }
                        total={userStats.clientsCreated}
                        height={36}
                      />
                    );
                  })
              : ''}
          </DataPanel>
        </div>
      </ExpandablePanel> */}
      <div className="row-container">
        <XpektorButton
          content="Lägg till klient"
          icon={faPlus}
          onClick={() => {
            setAction('createClient');
            setClientToEdit([]);
            setShowCreateClientModal(true);
          }}
        />
      </div>
      <ClientTable
        sortByName={sortByName}
        sortByDate={sortByDate}
        order={order}
      >
        {clients.map((data) => {
          return (
            <ClientTable.Item
              client={data}
              key={data.id}
              onNotificationClick={() => {
                setClientToEdit(data);
                setShowNotificationModal(true);
              }}
              onUserAccessClick={() => {
                setClientToEdit(data);
                setShowUserAccessModal(true);
              }}
              onEditClick={() => {
                setClientToEdit(data);
                setAction('updateClient');
                setShowEditClientModal(true);
              }}
              onDeleteClick={() => {
                setClientToEdit(data);
                setShowDeleteModal(true);
              }}
            />
          );
        })}
      </ClientTable>
      {showCreateClientModal && (
        <CreateAuthLinkModal
          handleClose={() => setShowCreateClientModal(false)}
        />
      )}

      {showNotificationModal && (
        <EditNotificationModal
          handleClose={() => setShowNotificationModal(false)}
          client={clientToEdit}
          customerId={currentUser.customerId}
        />
      )}
      {showUserAccessModal && (
        <HandleUserClientAccess
          handleClose={() => setShowUserAccessModal(false)}
          client={clientToEdit}
          customerId={currentUser.customerId}
        />
      )}
      {showEditClientModal && (
        <CreateClientPopUp
          handleClose={() => setShowEditClientModal(false)}
          action={action}
          clientToEdit={clientToEdit}
        />
      )}
      {showAuthClient && (
        <AuthenticateClient handleClose={() => setShowAuthClient(false)} />
      )}

      {showDeleteModal && (
        <DeleteModal
          show={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          action={action}
          clientToEdit={clientToEdit}
          customerId={customerId}
        />
      )}
    </div>
  );
}
